import React from 'react';
// import PDFDocument from 'pdfkit';
import blobStream from 'blob-stream';
import { MContext } from './settingsContext';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export default class PdfGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  static contextType = MContext;

  Donut(cx, cy, radius, data) {

    function arcradius(cx, cy, radius, degrees) {
        var radians = (degrees) * Math.PI / 180.0;
        return { x: cx + (radius * Math.cos(radians)), y: cy + (radius * Math.sin(radians)) };
    }

    var decimals = 4;
    var total = 0;
    var arr = [];
    var beg = 0;
    var end = 0;
    var count = 0;

    var half = radius / 2;
    var midpoint = radius / 2.4;

    for (var i = 0; i < data.length; i++)
        total += data[i].value;

    for (var i = 0; i < data.length; i++) {
        var item = data[i];
        var tmp = {};

        var p = (((item.value + 1) / total) * 100).toFixed(2);

        count += p;

        if (i === data.length - 1 && count < 100)
            p = p + (100 - count);

        end = beg + ((360 / 100) * p);

        var mid = (end + beg) / 2
        
        tmp.index = i;
        tmp.value = item.value;
        tmp.data = item;
        
        var b = arcradius(cx, cy, radius, end);
        var e = arcradius(cx, cy, radius, beg);
        var m = arcradius(cx, cy, radius + 20, mid);
        var la = (end - beg) <= 180 ? 0 : 1;

        tmp.d = ['M', b.x.toFixed(decimals), b.y.toFixed(decimals), 'A', radius, radius, 0, la, 0, e.x.toFixed(decimals), e.y.toFixed(decimals)].join(' ');
        tmp.m = m;
        arr.push(tmp);
        beg = end;
    }
 
    return arr;
  }

  generatePdf2(datasheet, imageSelected, ctx) {
    
    const homeNameFirst = ctx.state.homeNameFirst;
    const homeNameLast = ctx.state.homeNameLast;

    return new Promise((resolve, reject) => {
      const url = './image/template3.pdf';
      fetch(url, {
        headers : { 
          'Content-Type': 'application/pdf',
          'Accept': 'application/pdf',
          // "Accept": "application/octet-stream",
         }
      })
      .then(res => {
        console.log(res);
        return res.arrayBuffer();
      })
      .then(existingPdfBytes => PDFDocument.load(existingPdfBytes))
      .then(pdfDoc => {
        
        const pages = pdfDoc.getPages();
        console.log(pages);

        function capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
        }

        function addText(page, text, x, y, size, color) {
          page.drawText(`${text}`, {
            x: x, y: y, size: size, color: color
          });  
        }

        let imageSelectedFilter = imageSelected.filter(x => x.clicked === true);
        imageSelectedFilter.sort((a, b) => a.order - b.order);

        const index1 = imageSelectedFilter[0]['index'];
        const index2 = imageSelectedFilter[1]['index'];

        const firstPage = pages[0];
        const { width, height } = firstPage.getSize();
        console.log(width, height); // 595.22 842
        firstPage.drawText(`${capitalizeFirstLetter(homeNameFirst)} ${capitalizeFirstLetter(homeNameLast)}`, {
          x: width / 2 - (homeNameFirst.length + homeNameLast.length + 1) * 7.5,
          y: 525,
          size: 36,
          color: rgb(1.0, 1.0, 1.0),
          // font: helveticaFont,
        });
        
        const imageData1 = datasheet[index1]["image"];
        const layoutData1 = JSON.parse(datasheet[index1]["layout"][0]);

        let number_of_staff = layoutData1['number_of_staff'];
        let percent_of_permanent_staff = layoutData1['percent_of_permanent_staff'];
        let total_surface_area = layoutData1['total_surface_area'];

        let number_of_closed_door = layoutData1['number_of_closed_door'] ? layoutData1['number_of_closed_door'] : 0;
        const number_of_closed_glazed_panel = layoutData1['number_of_closed_glazed_panel'] ? layoutData1['number_of_closed_glazed_panel'] : 0;
        const number_of_closed_solid_panel = layoutData1['number_of_closed_solid_panel'] ? layoutData1['number_of_closed_solid_panel'] : 0;
        
        const number_of_meeting_door = layoutData1['number_of_meeting_door'] ? layoutData1['number_of_meeting_door'] : 0;
        const number_of_meeting_glazed_panel = layoutData1['number_of_meeting_glazed_panel'] ? layoutData1['number_of_meeting_glazed_panel'] : 0;
        const number_of_meeting_solid_panel = layoutData1['number_of_meeting_solid_panel'] ? layoutData1['number_of_meeting_solid_panel'] : 0;
        
        const number_of_open = layoutData1['number_of_open'] ? layoutData1['number_of_open'] : 0;
        
        const number_of_pods = layoutData1['number_of_pod'] ? layoutData1['number_of_pod'] : 0;

        const meeting_area_percent = layoutData1['meeting_area_percent'];
        const closed_area_percent = layoutData1['closed_area_percent'];
        const open_area_percent = layoutData1['open_area_percent'];

        percent_of_permanent_staff = percent_of_permanent_staff  + "%";
        total_surface_area = total_surface_area  + "sqm";
        
        const number_of_total_room = number_of_closed_door + number_of_meeting_door + number_of_pods;
        const number_of_total_solid_panel = number_of_closed_solid_panel + number_of_meeting_solid_panel;
        const number_of_total_glazed_panel = number_of_closed_glazed_panel + number_of_meeting_glazed_panel;
        const number_of_total_doors = number_of_closed_door + number_of_meeting_door;

        const secondPage = pages[1];
        
        const black = rgb(0.0, 0.0, 0.0);
        const green = rgb(151/255, 199/255, 191/255);
        const blue = rgb(70/255, 87/255, 103/255);
        const orange = rgb(229/255, 167/255, 109/255);

        // top table
        addText(secondPage, total_surface_area, 198, 652, 12, blue);
        addText(secondPage, number_of_staff, 198, 629, 12, blue);
        addText(secondPage, percent_of_permanent_staff, 198, 603, 12, blue);
        
        // bottom table
        addText(secondPage, number_of_closed_door, 166, 233, 12, blue);
        addText(secondPage, number_of_meeting_door, 166, 204, 12, blue);
        addText(secondPage, number_of_pods, 166, 179, 12, blue);
        addText(secondPage, number_of_total_room, 166, 157, 12, blue);
        
        addText(secondPage, number_of_closed_solid_panel, 259, 233, 12, orange);
        addText(secondPage, number_of_meeting_solid_panel, 259, 204, 12, orange);
        addText(secondPage, `-`, 259, 179, 12, orange);
        addText(secondPage, number_of_total_solid_panel, 259, 157, 12, orange);
        
        addText(secondPage, number_of_closed_glazed_panel, 351, 233, 12, orange);
        addText(secondPage, number_of_meeting_glazed_panel, 351, 204, 12, orange);
        addText(secondPage, `-`, 351, 179, 12, orange);
        addText(secondPage, number_of_total_glazed_panel, 351, 157, 12, orange);

        addText(secondPage, number_of_closed_door, 444, 233, 12, orange);
        addText(secondPage, number_of_meeting_door, 444, 204, 12, orange);
        addText(secondPage, `-`, 444, 179, 12, orange);
        addText(secondPage, number_of_total_doors, 444, 157, 12, orange);

        // add svg to layout 1
        
        // meeting orange #e5a76d
        // closed green #97C7BF
        // open blue #465767

        var data1 = [{ value: meeting_area_percent }, { value: closed_area_percent }, { value: open_area_percent }];
        var color = [rgb(229/255, 167/255, 109/255), rgb(151/255, 199/255, 191/255), rgb(70/255, 87/255, 103/255)];
        var centerX = 0;
        var centerY = 0;
        var radius = 40;

        let arr1 = this.Donut(centerX, centerY, radius, data1);
        
        const x_offset = 421.6;
        const y_offset = 632.7;
        secondPage.moveTo(x_offset, y_offset);
        for (var i = 0; i < arr1.length; i++) {
          var item = arr1[i];
          // svgPath1 += `<g><path d="${item.d}" stroke="${color[i]}" fill="none" stroke-width="50" /></g>`;
          secondPage.drawSvgPath(item.d, { borderColor: color[i], borderWidth: 20 });
          addText(secondPage, item.value + "%", x_offset + item.m.x - 7, y_offset - item.m.y - 1, 8, blue);
        }
                
        // secondPage.moveTo(0, 0);

        //// third page
        const imageData2 = datasheet[index2]["image"];
        const layoutData2 = JSON.parse(datasheet[index2]["layout"][0]);

        let number_of_staff2 = layoutData2['number_of_staff'];
        let percent_of_permanent_staff2 = layoutData2['percent_of_permanent_staff'];
        let total_surface_area2 = layoutData2['total_surface_area'];

        let number_of_closed_door2 = layoutData2['number_of_closed_door'] ? layoutData2['number_of_closed_door'] : 0;
        const number_of_closed_solid_panel2 = layoutData2['number_of_closed_solid_panel'] ? layoutData2['number_of_closed_solid_panel'] : 0;
        const number_of_closed_glazed_panel2 = layoutData2['number_of_closed_glazed_panel'] ? layoutData2['number_of_closed_glazed_panel'] : 0;
        
        const number_of_meeting_door2 = layoutData2['number_of_meeting_door'] ? layoutData2['number_of_meeting_door'] : 0;
        const number_of_meeting_solid_panel2 = layoutData2['number_of_meeting_solid_panel'] ? layoutData2['number_of_meeting_solid_panel'] : 0;
        const number_of_meeting_glazed_panel2 = layoutData2['number_of_meeting_glazed_panel'] ? layoutData2['number_of_meeting_glazed_panel'] : 0;
        
        let number_of_open2 = layoutData2['number_of_open'] ? layoutData2['number_of_open'] : 0;
        
        const number_of_pods2 = layoutData2['number_of_pod'] ? layoutData2['number_of_pod'] : 0;

        const meeting_area_percent2 = layoutData2['meeting_area_percent'];
        const closed_area_percent2 = layoutData2['closed_area_percent'];
        const open_area_percent2 = layoutData2['open_area_percent'];

        percent_of_permanent_staff2 = percent_of_permanent_staff2  + "%";
        total_surface_area2 = total_surface_area2  + "sqm";
        
        const number_of_closed_solid_panel_reused = (number_of_closed_solid_panel2 - number_of_total_solid_panel) > 0 ? number_of_total_solid_panel : number_of_closed_solid_panel2;
        const number_of_closed_solid_panel_add = (number_of_closed_solid_panel2 - number_of_total_solid_panel) > 0 ? (number_of_closed_solid_panel2 - number_of_total_solid_panel) : 0;
        
        const number_of_closed_glazed_panel_reused = (number_of_closed_glazed_panel2 - number_of_total_glazed_panel) > 0 ? number_of_total_glazed_panel : number_of_closed_glazed_panel2;
        const number_of_closed_glazed_panel_add = (number_of_closed_glazed_panel2 - number_of_total_glazed_panel) > 0 ? (number_of_closed_glazed_panel2 - number_of_total_glazed_panel) : 0;
        
        const number_of_meeting_solid_panel_reused = (number_of_meeting_solid_panel2 + number_of_closed_solid_panel_reused - number_of_total_solid_panel) > 0 ? (number_of_total_solid_panel - number_of_closed_solid_panel_reused) : number_of_meeting_solid_panel2;
        const number_of_meeting_solid_panel_add = (number_of_meeting_solid_panel2 + number_of_closed_solid_panel_reused - number_of_total_solid_panel) > 0 ? (number_of_meeting_solid_panel2 + number_of_closed_solid_panel_reused - number_of_total_solid_panel) : 0;

        const number_of_meeting_glazed_panel_reused = (number_of_meeting_glazed_panel2 + number_of_closed_glazed_panel_reused - number_of_total_glazed_panel) > 0 ? (number_of_total_glazed_panel - number_of_closed_glazed_panel_reused) : number_of_meeting_glazed_panel2;
        const number_of_meeting_glazed_panel_add = (number_of_meeting_glazed_panel2 + number_of_closed_glazed_panel_reused - number_of_total_glazed_panel) > 0 ? (number_of_meeting_glazed_panel2 + number_of_closed_glazed_panel_reused - number_of_total_glazed_panel) : 0;

        number_of_open2 = number_of_open2 + " (" + ((number_of_open2 - number_of_open) >= 0 ? "+" : "") + (number_of_open2 - number_of_open) + ")";
        
        const number_of_total_room2 = number_of_closed_door2 + number_of_meeting_door2 + number_of_pods2;
        const number_of_total_solid_panel2_reused = number_of_closed_solid_panel_reused + number_of_meeting_solid_panel_reused;
        const number_of_total_solid_panel2_add = number_of_closed_solid_panel_add + number_of_meeting_solid_panel_add;
        const number_of_total_glazed_panel2_reused = number_of_closed_glazed_panel_reused + number_of_meeting_glazed_panel_reused;
        const number_of_total_glazed_panel2_add = number_of_closed_glazed_panel_add + number_of_meeting_glazed_panel_add;
        const number_of_total_doors2 = number_of_closed_door2 + number_of_meeting_door2;

        const thirdPage = pages[2];

        // top table
        // if its increased, green
        // otherwise blue
        addText(thirdPage, total_surface_area2, 198, 652, 12, (total_surface_area2 === total_surface_area) ? blue : (total_surface_area2 > total_surface_area) ? orange : green);
        addText(thirdPage, number_of_staff2, 198, 629, 12, (number_of_staff2 === number_of_staff) ? blue : (number_of_staff2 > number_of_staff) ? orange : green);
        addText(thirdPage, percent_of_permanent_staff2, 198, 603, 12, (percent_of_permanent_staff2 === percent_of_permanent_staff) ? blue : (percent_of_permanent_staff2 > percent_of_permanent_staff) ? orange : green);
        
        // bottom table
        addText(thirdPage, number_of_closed_door2, 131, 233, 12, blue);
        addText(thirdPage, number_of_meeting_door2, 131, 202, 12, blue);
        addText(thirdPage, number_of_pods2, 131, 173, 12, blue);
        addText(thirdPage, number_of_total_room2, 131, 152, 12, blue);

        addText(thirdPage, number_of_closed_solid_panel_reused, 185, 233, 12, green);
        addText(thirdPage, number_of_meeting_solid_panel_reused, 185, 202, 12, green);
        addText(thirdPage, `-`, 185, 173, 12, green);
        addText(thirdPage, number_of_total_solid_panel2_reused, 185, 152, 12, green);

        addText(thirdPage, number_of_closed_solid_panel_add, 257, 233, 12, orange);
        addText(thirdPage, number_of_meeting_solid_panel_add, 257, 202, 12, orange);
        addText(thirdPage, `-`, 257, 173, 12, orange);
        addText(thirdPage, number_of_total_solid_panel2_add, 257, 152, 12, orange);

        addText(thirdPage, number_of_closed_glazed_panel_reused, 335, 233, 12, green);
        addText(thirdPage, number_of_meeting_glazed_panel_reused, 335, 202, 12, green);
        addText(thirdPage, `-`, 335, 173, 12, green);
        addText(thirdPage, number_of_total_glazed_panel2_reused, 335, 152, 12, green);

        addText(thirdPage, number_of_closed_glazed_panel_add, 413, 233, 12, orange);
        addText(thirdPage, number_of_meeting_glazed_panel_add, 413, 202, 12, orange);
        addText(thirdPage, `-`, 413, 173, 12, orange);
        addText(thirdPage, number_of_total_glazed_panel2_add, 413, 152, 12, orange);

        addText(thirdPage, number_of_closed_door2, 491, 233, 12, orange);
        addText(thirdPage, number_of_meeting_door2, 491, 202, 12, orange);
        addText(thirdPage, `-`, 491, 173, 12, orange);
        addText(thirdPage, number_of_total_doors2, 491, 152, 12, orange);

        var data2 = [{ value: meeting_area_percent2 }, { value: closed_area_percent2 }, { value: open_area_percent2 }];
        let arr2 = this.Donut(centerX, centerY, radius, data2);
        
        thirdPage.moveTo(x_offset, y_offset);

        for (var i = 0; i < arr2.length; i++) {
          var item = arr2[i];
          thirdPage.drawSvgPath(item.d, { borderColor: color[i], borderWidth: 20 })
          addText(thirdPage, item.value + "%", x_offset + item.m.x - 7, y_offset - item.m.y - 1, 8, blue);
        }


        // add image 1
        Promise.all([pdfDoc.embedPng(imageData1), pdfDoc.embedPng(imageData2)])
        .then(([pngImage1, pngImage2]) => {

          // lock to max height
          const maxWidth = 560;
          const maxHeight = 233; // fix to height always ? 
          
          const pngDims1 = pngImage1.scale(1.0);      
          
          const maxRatio = maxWidth / maxHeight;
          const pngRatio1 = pngDims1.width / pngDims1.height;

          if (maxRatio > pngRatio1) { // if image height is larger , then constraint to height
            const scaledWidth1 = (maxHeight / pngDims1.height) * pngDims1.width;
            secondPage.drawImage(pngImage1, {
              x: (595.22 / 2) - (scaledWidth1 / 2),
              y: 302,
              width: scaledWidth1,
              height: maxHeight
            });
          } else { // constraint to width
            const scaledHeight1 = (maxWidth / pngDims1.width) * pngDims1.height;
            secondPage.drawImage(pngImage1, {
              x: (595.22 / 2) - (maxWidth / 2),
              y: 302,
              width: maxWidth,
              height: scaledHeight1
            });
          }

          const pngDims2 = pngImage2.scale(1.0);
          const pngRatio2 = pngDims2.width / pngDims2.height;

          if (maxRatio > pngRatio2) { // if image height is larger , then constraint to height
            const scaleWidth2 = (maxHeight / pngDims2.height) * pngDims2.width;
            thirdPage.drawImage(pngImage2, {
              x: (595.22 / 2) - (scaleWidth2 / 2),
              y: 302,
              width: scaleWidth2,
              height: maxHeight
            });
          } else { // constraint to width
            const scaledHeight2 = (maxWidth / pngDims2.width) * pngDims2.height;
            thirdPage.drawImage(pngImage2, {
              x: (595.22 / 2) - (maxWidth / 2),
              y: 302,
              width: maxWidth,
              height: scaledHeight2
            });
          }
        })
        .then(_ => {
          pdfDoc.setTitle('Your Clestra Datasheet')
          pdfDoc.setAuthor('Clestra Ltd')
          pdfDoc.setSubject('Modular Planner')
          pdfDoc.setProducer('Produced by YKS Group')
          pdfDoc.setCreationDate(new Date())

          return pdfDoc.save();
        })
        .then(pdfBytes => {
          const blob = new Blob([pdfBytes], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          console.log(url);
          resolve(url);
        });
      })
      
    
    })
  }
}
  