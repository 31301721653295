import React, { Component } from 'react'

export const MContext = React.createContext();
export class MyProvider extends Component {
    state = {
        settingsShape: '',
        settingsConfigPeople: 20,
        settingsConfigFloorArea: 200,
        settingsConfigOccupiedPercent: 30,
        settingsOfficeOpen: 33.3,
        settingsOfficeMeeting: 33.3,
        settingsOfficeClosed: 33.3,
        settingsImage: null,
        homeNameFirst: null,
        homeNameLast: null,
        homeEmail: null,
        homeCompany: null,
        homeCountry: null,
        homeTick: false,
        imageData: [],
        imageSelected: [],
    }
    
    render() {
        return (
            <MContext.Provider value={{   
                state: this.state,
                homeNameFirst: (value) => this.setState({
                    homeNameFirst: value.homeNameFirst
                }),
                homeNameLast: (value) => this.setState({
                    homeNameLast: value.homeNameLast
                }),
                homeEmail: (value) => this.setState({
                    homeEmail: value.homeEmail
                }),
                homeCompany: (value) => this.setState({
                    homeCompany: value.homeCompany
                }),
                homeCountry: (value) => this.setState({
                    homeCountry: value.homeCountry
                }),
                homeTick: (value) => this.setState({
                    homeTick: value.homeTick
                }),
                settingsShape: (value) => this.setState({
                    settingsShape: value.settingsShape
                }),
                settingsConfigPeople: (value) => this.setState({
                    settingsConfigPeople: value.settingsConfigPeople
                }),
                settingsConfigFloorArea: (value) => this.setState({
                    settingsConfigFloorArea: value.settingsConfigFloorArea
                }),
                settingsConfigOccupiedPercent: (value) => this.setState({
                    settingsConfigOccupiedPercent: value.settingsConfigOccupiedPercent
                }),
                settingsOffice: (value) => this.setState({
                    settingsOfficeOpen: value.settingsOfficeOpen,
                    settingsOfficeMeeting: value.settingsOfficeMeeting,
                    settingsOfficeClosed: value.settingsOfficeClosed
                }),
                settingsImage: (value) => this.setState({
                    settingsImage: value.settingsImage
                }),
                imageData: (value) => this.setState({
                    imageData: value.imageData
                }),
                imageSelected: (value) => this.setState({
                    imageSelected: value.imageSelected
                })
                }}>
            {this.props.children}   
            </MContext.Provider>
        )
    }
}



