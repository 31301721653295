import React, { useState, useContext } from 'react';
// import Grid from '@material-ui/core/Grid';
import useStyles from './settings-styles';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
// import Button from '@material-ui/core/Button';
import { ExpandMore, ExpandLess, CloudUpload, FeedbackOutlined } from '@material-ui/icons';
import handleNavigationDrawer from './hooks/handleNavigationDrawer';

import { Grid, TextField, Checkbox, Button, Typography, Slider } from '@material-ui/core';
import { withStyles  } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Paper from '@material-ui/core/Paper';
import 'fontsource-roboto';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PieChart from "./PieChart";
import FormattedInputs from "./FormattedInputs";

import { MContext } from './settingsContext';

const pdfjsLib = require("pdfjs-dist/legacy/build/pdf.js");
const pdfjsWorker = require("pdfjs-dist/legacy/build/pdf.worker.entry.js");

export default function() {
    const { toggleDrawerOpen, open } = handleNavigationDrawer();
    const classes = useStyles();

    // static contextType = MContext;
    const context = useContext(MContext);

    const handleToggleChangeShapeChoice = (e, value) => {
        // setState({
        //     settingsShape: value
        // })
        const sc = {
            settingsShape: value
        }
        const settingsContext = context;
        settingsContext.settingsShape(sc);
    }
    
    const handleToggleChangeOccupancyVal = (e, value) => {
        var value = parseInt(e.target.value, 10);
        const max = 200;
        const min = 1;
        if (value > max) {
            value = max;
        }
        if (value < min) {
            value = min;
        }
        const sc = {
            settingsConfigPeople: value
        }
        const settingsContext = context;
        settingsContext.settingsConfigPeople(sc);
    }

    const handleToggleChangeOccupiedArea = (e, value) => {
        var value = parseInt(e.target.value, 10);
        const max = 2000;
        const min = 1;
        if (value > max) {
            value = max;
        }
        if (value < min) {
            value = min;
        }
        const sc = {
            settingsConfigFloorArea: value
        }
        const settingsContext = context;
        settingsContext.settingsConfigFloorArea(sc);
    }

    const handleToggleChangeOccupiedPercent = (e, value) => {
        const sc = {
            settingsConfigOccupiedPercent: value
        }
        const settingsContext = context;
        settingsContext.settingsConfigOccupiedPercent(sc);
    }
    
    const handleCallbackPieChart = (pieChartData) => {
        // setState({
        //     settingsOfficeOpen: pieChartData.openProportion,
        //     settingsOfficeMeeting: pieChartData.meetingProportion,
        //     settingsOfficeClosed: pieChartData.closedProportion
        // })
        const sc = {
            settingsOfficeOpen: pieChartData.openProportion,
            settingsOfficeMeeting: pieChartData.meetingProportion,
            settingsOfficeClosed: pieChartData.closedProportion
        }
        const settingsContext = context;
        settingsContext.settingsOffice(sc);
    }

    const handleImageUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            const ext = file.name.split('.').pop();

            if (ext.toLowerCase() === "pdf") {

                // try pdf                
                const url = URL.createObjectURL(file);
                pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
                // pdfjsLib.disableWorker = true;
                const loadingTask = pdfjsLib.getDocument(url);
                
                loadingTask.promise
                .then((doc) => {
                    const numPages = doc.numPages;
                    console.log("# Document Loaded");
                    console.log("Number of Pages: " + numPages);
                    
                    if (numPages > 0) {
                        doc.getPage(1)
                        .then((page) => {  
                            const viewport = page.getViewport({ scale: 5.0 });
                            
                            const canvas = document.createElement('canvas');
                            
                            canvas.width = viewport.width;
                            canvas.height = viewport.height;
            
                            const ctx = canvas.getContext('2d');
                            const renderContext = {
                                canvasContext: ctx,
                                viewport: viewport
                            };
            
                            var renderTask = page.render(renderContext)
                            renderTask.promise.then(() => {
                                const data = canvas.toDataURL();

                                const settingsContext = context;
                                if (settingsContext.state.settingsImage) {
                                    URL.revokeObjectURL(settingsContext.state.settingsImage)
                                }                        
                                const sc = {
                                    settingsImage: data
                                }
                                settingsContext.settingsImage(sc);
                            });
                        });
                    }
                    
                })
            } else {
                // is an image
                const settingsContext = context;
                if (settingsContext.state.settingsImage) {
                    URL.revokeObjectURL(settingsContext.state.settingsImage)
                }
                const img = URL.createObjectURL(file);
                
                const sc = {
                    settingsImage: img
                }
                settingsContext.settingsImage(sc);
            }

        }
    }

    return (
        <>
        <Button onClick={toggleDrawerOpen} className={classes.drawerButton}>
            {
                open ? <ExpandMore/> : <ExpandLess/>
            }
            <div className="introSettings">
            Edit Layout Settings
            </div>
        </Button>
        
          <Drawer
          anchor={"bottom"}
          open={open}
          onClose={toggleDrawerOpen}
          classes={{ paper: classes.paperTransparent }}
          >
              <Button onClick={toggleDrawerOpen} className={classes.drawerButton}>
                {
                    open ? <ExpandMore/> : <ExpandLess/>
                }
                Apply Layout Settings
            </Button>
          <Grid container justify="center" spacing={2} className={classes.drawerBody}>
          <div className={classes.helpDrawer}>
            
            <Grid container direction="row" justifyContent="center">
                

                <Grid item>
                    <div className={classes.paper}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="subtitle1" display="block" gutterBottom className={classes.task}>
                                A&#41; Adjust the Office Space Proportions
                                </Typography>
                            </Grid>
                            <Grid item>
                                <PieChart
                                defaultValue={ {
                                    settingsOfficeOpen: context.state.settingsOfficeOpen,
                                    settingsOfficeMeeting: context.state.settingsOfficeMeeting,
                                    settingsOfficeClosed: context.state.settingsOfficeClosed
                                } }
                                pieChartCallback={ handleCallbackPieChart }
                                width={"300"}
                                height={"300"}
                                >
                                </PieChart>
                            </Grid>

                            
                            

                        </Grid>
                    </div>
                </Grid>

                <Grid item>
                    <div className={classes.paper}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="subtitle1" display="block" gutterBottom className={classes.task}>
                                B&#41; Configure the Office Space Occupancy
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" display="block" gutterBottom className={classes.task}>
                                How many people are in the space?
                                </Typography>
                                <TextField 
                                type="number"
                                value={ context.state.settingsConfigPeople }                            
                                onChange={ handleToggleChangeOccupancyVal }
                                fullWidth="true"
                                hiddenLabel
                                // label="How many people are in the space?"
                                placeholder="A number"
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" display="block" gutterBottom className={classes.task}>
                                How many square meters is your space?
                                
                                </Typography>
                                <TextField 
                                type="number"
                                value={ context.state.settingsConfigFloorArea }                            
                                onChange={ handleToggleChangeOccupiedArea }
                                // value={this.value}
                                fullWidth="true"
                                hiddenLabel
                                // label="How many square meters is your space?" 
                                placeholder="A number"
                                />
                                {
                                    (context.state.settingsConfigPeople / context.state.settingsConfigFloorArea > 0.1) && (
                                        <Paper style={{marginTop: 2, paddingTop: 4, backgroundColor: 'rgb(151, 199, 191)', color: '#000'}}>
                                            {/* <FeedbackOutlined style={{marginLeft: '8px', marginRight: '8px'}}/>  */}
                                            Not sufficient floor area 
                                        </Paper>
                                    )
                                }
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" display="block" gutterBottom className={classes.task}>
                                    What percentage of staff is permanent?
                                </Typography>
                                <Slider
                                    value={ context.state.settingsConfigOccupiedPercent }
                                    onChange={ handleToggleChangeOccupiedPercent }
                                    // getAriaValueText={value}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={10}
                                    marks
                                    min={0}
                                    max={100}
                                />
                            </Grid>
                            
                            <Grid item>
                                <div className={classes.submitButtonNeg1}>
                                    <div className={classes.submitButton0}>
                                        <div className={classes.submitButton1}>
                                            <div className={classes.submitButton2}>
                                                <Button 
                                                variant="outlined" 
                                                color="primary"
                                                onClick={toggleDrawerOpen} 
                                                size="large" 
                                                className={classes.submitButton}>
                                                    Ok!
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                
                <Grid item>
                    <div className={classes.paper}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="subtitle1" display="block" gutterBottom className={classes.task}>
                                    C&#41; If you already possess a bare floorplan layout, upload it here (optional)
                                </Typography>
                            </Grid>
                            <Grid item>

                                <div className={classes.submitButtonNeg1}>
                                    <div className={classes.submitButton0}>
                                        <div className={classes.submitButton1}>
                                            <div className={classes.submitButton2}>
                                                <ToggleButton 
                                                style={{
                                                    // border: '1px solid black',
                                                    // backgroundColor: "white",
                                                    color: 'black'
                                                }}
                                                variant="outlined" 
                                                color="primary"
                                                // onClick={toggleDrawerOpen} 
                                                size="large" 
                                                className={classes.submitButton}
                                                value="upload"
                                                component="label"
                                                >
                                                    <CloudUpload style={{marginRight: '8px'}}/>Upload File
                                                    <input type="file" accept="image/png, image/jpeg, application/pdf" multiple="false" onChange={ handleImageUpload } hidden />
                                                </ToggleButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <ToggleButton 
                                style={{
                                    border: '1px solid black',
                                    backgroundColor: "white",
                                    color: 'black'
                                }}
                                value="upload" variant="contained" component="label">
                                    <CloudUpload style={{marginRight: '8px'}}/>Upload File
                                <input type="file" accept="image/png, image/jpeg, application/pdf" multiple="false" onChange={ handleImageUpload } hidden />
                                </ToggleButton> */}
                            </Grid>

                            
                        </Grid>
                    </div>

                </Grid>

            </Grid>
          </div>
          </Grid>
          </Drawer>
        </>
    );
}