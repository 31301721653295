import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  modal: {
    backgroundColor: '#ffffffed',
    width: '35em',
    height: '35em',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 5,
    boxShadow:
      'box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);',
  },
  modalDisplayDatasheet: {
    backgroundColor: '#ffffffed',
    width: '35em',
    height: '25em',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 5,
    boxShadow:
      'box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);',
  },
  textfield: {
    minHeight: 200,
    marginLeft: 25,
  },
  textinput: {
    width: '60%',
    paddingBottom: 8,
  },
  cancelButton: {
    marginLeft: 25,
  },
  submitButton: {
    borderRadius:0, 
    border: 'none',
    backgroundColor: 'none',
    fontWeight: 'bold',
    '&:hover': {
      border: 'none',
    }
  },
  submitButtonNeg1: {
    display: 'flex',
    justifyContent: 'center',
    height: '4em',
  },
  submitButton0: {
    border: "1px solid grey", 
    marginTop: '1em', 
    width: 'fit-content', 
    height: 'fit-content', 
  },
  submitButton1: {
    backgroundColor: "rgb(151, 199, 191)", 
    position: 'relative', 
    right: -6, 
    bottom: 8,
    '&:hover': {
      right: -2, 
      bottom: 4,
      transition: '0.25s',
      backgroundColor: 'rgb(151 199 191 / 30%)',
      border: 'none',
    },
  },
  submitButton2: {
    position: 'relative', 
    left: 8, 
    top: 4,
    border: '3px solid #e5a76d',
    '&:hover': {
      left: 2, 
      transition: '0.25s',
      border: 'none',
    },
  },
  closeButton: {
    position: 'absolute', 
    top: 16,
    right: 25,
  },
  title: {
    marginTop: 24,
    marginBottom: 8,
    marginLeft: 25,
  },
  contactdetails: {
      marginLeft: 32,
      marginRight: 32,
  },
  agreement: {
    marginTop: 8,
  },
  overlay: {
    height: '100%',
},
});
