export default async function solve(json_string) {
    try {

        const body = {
            solve: json_string
        };

        const url = 'https://fpesouq2jwt2xln75hkganlmfu0hwlec.lambda-url.ap-southeast-1.on.aws/';
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify(body)
        })

        const data = await res.json();

        const signedUrl = data.signedUrl;

        const signedUrl_res = await fetch(signedUrl);
        
        const signedUrl_data = await signedUrl_res.json();

        let mesh_data = signedUrl_data.values[0].InnerTree['{0}'].map(function(x) {return JSON.parse(x.data)})
        let string_data = signedUrl_data.values[1].InnerTree['{0}'].map(function(x) {return JSON.parse(x.data)})

        return {"mesh_data": mesh_data, "string_data": string_data};
    } catch (error) {
        console.error(error);
        return;
    }
}
