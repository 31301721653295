import React, { useState, useEffect } from 'react';
import { Grid, TextField, Checkbox, Button, Typography, Slider, MenuItem } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import useStyles from './tips-styles';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function ({
    handleOpenModal,
    handleStartIntro
}) {
    
    const [openModal, setOpenModal] = useState(true);
    const classes = useStyles();
    
    useEffect(() => {
        if (handleOpenModal !== null) {
            setOpenModal(true);
        }
    }, [handleOpenModal])

    const handleCloseModal = () => {
        setOpenModal(false);
        handleStartIntro();
    }

    return (
        <Modal
            open={openModal}
            // onClose={() => setOpenModal(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableAutoFocus
        >
            <div className={classes.modal}>
                <Typography variant="h6" >
                    <span className={classes.offcolour}>Work will never cease to evolve, nor should your workspaces.</span>
                </Typography>
                <img className={classes.image} src="image\55976_pictos_piliers_2.png" width={200}/>
                <Typography variant="h6" >
                <span className={classes.offcolour}>Experience</span> modular workspace construction <span className={classes.offcolour}>in a few steps. Don't forget to download your</span> space datasheet <span className={classes.offcolour}>before leaving!</span>
                </Typography>
                {/* <h4 className={classes.title}>
                    
                </h4> */}
                {/* <Carousel
                autoPlay
                infiniteLoop
                showArrows={false}
                showThumbs={false}
                useKeyboardArrows={true}
                swipeable={true}
                emulateTouch={true}
                dynamicHeight={false}
                showStatus={false}
                >
                    <div>
                        <img src="image/clestra_landing_3.png" />
                        <p className="legend">Hi there! This is the office space planner. Swipe left to find out how to design your own office layout. Remember, All generated layouts are for reference purposes and are not actual architecture layouts.</p>
                    </div>
                    <div>
                        <img src="image/img8.png" />
                        <p className="legend">Configure the office layout by opening the orange drawer below.</p>
                    </div>
                    <div>
                        <img src="image/img4.png" />
                        <p className="legend">Draw a floor plan on the canvas using the walls and doors buttons. If you make a mistake, just hit the rewind button!</p>
                    </div>
                    <div>
                        <img src="image/img5.png" />
                        <p className="legend">Finally, generate a layout and create the datasheet.</p>
                    </div>
                </Carousel> */}
                <div className={classes.submitButtonNeg1}>
                    <div className={classes.submitButton0}>
                        <div className={classes.submitButton1}>
                            <div className={classes.submitButton2}>

                            <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleCloseModal}
                            className={classes.submitButton}
                            >
                                {/* <Typography variant="h5" className={classes.paragraph}> */}
                                    Let's Go!
                                {/* </Typography> */}
                            </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}