import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
    //   thousandSeparator
      suffix=" square meters"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

class FormattedInputs extends React.Component {
  state = {
    numberformat: "100"
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { numberformat } = this.state;

    return (
      <TextField
        label="What is the floor area of the space?"
        value={numberformat}
        onChange={this.handleChange("numberformat")}
        id="formatted-numberformat-input"
        fullWidth="true"
        InputProps={{
          inputComponent: NumberFormatCustom
        }}
      />
    );
  }
}

export default FormattedInputs;