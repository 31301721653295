import React, { useState, useContext, useEffect } from 'react';
// import Grid from '@material-ui/core/Grid';
import useStyles from './proceed-styles';
import Drawer from '@material-ui/core/Drawer';
import { ExpandMore, ExpandLess, CloudUpload, ArrowForward, ArrowBack, ExitToApp, ArrowDownward } from '@material-ui/icons';
import handleNavigationDrawer from './hooks/handleNavigationDrawer';
import { Grid, TextField, Checkbox, Button, Typography, Slider } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import Modal from '@material-ui/core/Modal';
import PieChart from "./PieChart";
import { MContext } from '../components/settingsContext';
import Paper from '@material-ui/core/Paper';

import 'fontsource-roboto';

export default function({goBack, goForward, goBackAndGenerateLayout}) {
  
  const [openModal, setOpenModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [layoutData, setLayoutData] = useState();
  const context = useContext(MContext);

  const classes = useStyles();

  useEffect(() => {
    if (context && context.state.imageData) {
      const newImageData = context.state.imageData;
      setLayoutData(newImageData);
    }
  }, [])

  const triggerOpenModal = () => {
    setOpenModal(true);
  }

  const handleGenerateDatasheet = () => {
    setOpenModal(false);
    setOpenSettingsModal(false);
    goForward();
  }

  const handleChangeSettings = () => {
    setOpenModal(false);
    setOpenSettingsModal(true);
  }

  const handleChangeSettingsSubmit = () => {
    setOpenSettingsModal(false);
    goBackAndGenerateLayout();
  }

  const handleToggleChangeOccupancyVal = (e, value) => {
    var value = parseInt(e.target.value, 10);
    const max = 200;
    const min = 1;
    if (value > max) {
        value = max;
    }
    if (value < min) {
        value = min;
    }
    const sc = {
        settingsConfigPeople: value
    }
    const settingsContext = context;
    settingsContext.settingsConfigPeople(sc);
  }

  const handleToggleChangeOccupiedArea = (e, value) => {
    // e.stopPropagation();
      var value = parseInt(e.target.value, 10);
      const max = 2000;
      const min = 1;
      if (value > max) {
          value = max;
      }
      if (value < min) {
          value = min;
      }
      const sc = {
          settingsConfigFloorArea: value
      }
      const settingsContext = context;
      settingsContext.settingsConfigFloorArea(sc);
  }

  const handleToggleChangeOccupiedPercent = (e, value) => {
    // e.stopPropagation();
      const sc = {
          settingsConfigOccupiedPercent: value
      }
      const settingsContext = context;
      settingsContext.settingsConfigOccupiedPercent(sc);
  }

  const handleCallbackPieChart = (pieChartData) => {
      // setState({
      //     settingsOfficeOpen: pieChartData.openProportion,
      //     settingsOfficeMeeting: pieChartData.meetingProportion,
      //     settingsOfficeClosed: pieChartData.closedProportion
      // })
      const sc = {
          settingsOfficeOpen: pieChartData.openProportion,
          settingsOfficeMeeting: pieChartData.meetingProportion,
          settingsOfficeClosed: pieChartData.closedProportion
      }
      const settingsContext = context;
      settingsContext.settingsOffice(sc);
  }

  return (
      <>
        <div className={classes.submitButtonNeg1}>
            <div className={classes.submitButton0}>
                <div className={classes.submitButton1}>
                    <div className={classes.submitButton2}>
                        <Button 
                        variant="outlined" 
                        color="primary"
                        onClick={triggerOpenModal} 
                        size="large" 
                        className={classes.submitButton}>
                          <CheckIcon/>
                          Proceed
                        </Button>
                    </div>
                </div>
            </div>
        </div>


        { 
          layoutData && 
          (
            layoutData.length < 2 ? // if this is first layout
            (
            
            openModal ? 
            <Modal
            open={openModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableAutoFocus
            >
            <div className={classes.modal}>
                <Typography variant="body1" className={classes.paragraph}>
                You have successfully created your <span className={classes.offcolour}>first office layout! </span>
                </Typography>
                
                <Typography variant="body1" className={classes.paragraph}>
                Now, imagine what this office might look like in a few years time:
                </Typography>
                
                {/* <Button
                variant="contained"
                size="large"
                onClick={handleChangeSettings}
                className={classes.submitButton}
                >
                    <ArrowDownward />Simulate organisational changes
                </Button> */}

                <div className={classes.submitButtonNeg1Mid}>
                      <div className={classes.submitButton0}>
                          <div className={classes.submitButton1}>
                              <div className={classes.submitButton2}>
                                  <Button 
                                  variant="outlined" 
                                  color="primary"
                                  onClick={handleChangeSettings} 
                                  size="large" 
                                  className={classes.submitButton}>
                                    <ArrowDownward />
                                    Simulate organisational changes
                                  </Button>
                              </div>
                          </div>
                      </div>
                  </div>
                
                <Typography variant="body1" className={classes.paragraph}>
                Or start the process again:
                </Typography>

                {/* <Button
                variant="contained"
                size="large"
                onClick={goBack}
                className={classes.submitButton}
                >
                      <ArrowBack />Go back to the drawing board
                </Button> */}

                <div className={classes.submitButtonNeg1Mid}>
                      <div className={classes.submitButton0}>
                          <div className={classes.submitButton1}>
                              <div className={classes.submitButton2}>
                                  <Button 
                                  variant="outlined" 
                                  color="primary"
                                  onClick={goBack} 
                                  size="large" 
                                  className={classes.submitButton}>
                                    <ArrowBack />Go back to the drawing board
                                  </Button>
                              </div>
                          </div>
                      </div>
                  </div>

                <Button 
                onClick={() => setOpenModal(false)}
                className={classes.closeButton}
                >
                    X
                </Button >
            </div>
        </Modal> : 
            <Modal
            open={openSettingsModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableAutoFocus
            >
            <div className={classes.modal}>
                <Typography variant="body1" className={classes.paragraph_reverse}>
                <span className={classes.offcolour}>Simulate organisational changes</span>
                </Typography>
                
                {/* // previous settings  */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" className={classes.previousSettings}>
                    Previous Settings:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                  <Typography variant="body1" className={classes.previousSettings}>
                    {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['open_area_percent'])}% Open Space <br/>
                    {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['closed_area_percent'])}% Closed Space <br/>
                    {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['meeting_area_percent'])}% Meeting Space <br/>
                    {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['number_of_staff'])} Staff <br/>
                    {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['total_surface_area'])} square meters of Space <br/>
                    {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['percent_of_permanent_staff'])}% Permanent staff <br/>
                  </Typography>
                  </Grid>
                </Grid>

                {/* // new settings */}
                <div className={classes.helpDrawer}>
                  {/* <Grid container direction="row" justifyContent="center"> */}
                  <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <Typography variant="body1" className={classes.previousSettings}>
                    New Settings:
                    </Typography>
                  </Grid>

                    <Grid item xs={6}>
                        <div className={classes.paper}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                <Typography variant="body1" display="block" gutterBottom className={classes.task}>
                                    {/* A&#41; Adjust the Office Space Proportions */}
                                    {/* New Settings: */}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <PieChart
                                    defaultValue={ {
                                        settingsOfficeOpen: context.state.settingsOfficeOpen,
                                        settingsOfficeMeeting: context.state.settingsOfficeMeeting,
                                        settingsOfficeClosed: context.state.settingsOfficeClosed
                                    } }
                                    pieChartCallback={ handleCallbackPieChart }
                                    width={"220"}
                                    height={"220"}
                                    >
                                    </PieChart>
                                </Grid>
                            </Grid>
                        </div>

                    </Grid>

                    <Grid item xs={6}>
                        <div className={classes.paper}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                  <Typography variant="body1" display="block" gutterBottom className={classes.task}>
                                    {/* B&#41; Configure the Office Space Occupancy */}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" display="block" gutterBottom className={classes.previousSettings}>
                                    How many people are in the space?
                                    </Typography>
                                    <TextField 
                                    type="number"
                                    value={ context.state.settingsConfigPeople }                            
                                    onChange={ handleToggleChangeOccupancyVal }
                                    fullWidth="true"
                                    hiddenLabel
                                    // label="How many people are in the space?"
                                    placeholder="A number"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" display="block" gutterBottom className={classes.previousSettings}>
                                    How many square meters is your space?
                                    </Typography>
                                    <TextField 
                                    type="number"
                                    value={ context.state.settingsConfigFloorArea }                            
                                    onChange={ handleToggleChangeOccupiedArea }
                                    // value={this.value}
                                    fullWidth="true"
                                    hiddenLabel
                                    // label="How many square meters is your space?" 
                                    placeholder="A number"
                                    />
                                    {
                                      (context.state.settingsConfigPeople / context.state.settingsConfigFloorArea > 0.1) && (
                                        <Paper style={{marginTop: 2, paddingTop: 4, backgroundColor: 'rgb(151, 199, 191)', color: '#000'}}>
                                            Not sufficient floor area 
                                        </Paper>
                                      )
                                    }
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" display="block" gutterBottom className={classes.previousSettings}>
                                        What percentage of staff is permanent?
                                    </Typography>
                                    <Slider
                                        value={ context.state.settingsConfigOccupiedPercent }
                                        onChange={ handleToggleChangeOccupiedPercent }
                                        // getAriaValueText={value}
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="auto"
                                        step={10}
                                        marks
                                        min={0}
                                        max={100}
                                    />
                                </Grid>
                                

                            </Grid>
                        </div>
                    </Grid>
                  </Grid>
                </div>         

                {/* <Button
                variant="contained"
                size="large"
                onClick={handleChangeSettingsSubmit}
                className={classes.submitButton}
                >
                    Proceed
                </Button> */}
                
                <div className={classes.submitButtonNeg1Mid}>
                  <div className={classes.submitButton0}>
                      <div className={classes.submitButton1}>
                          <div className={classes.submitButton2}>
                              <Button 
                              variant="outlined" 
                              color="primary"
                              onClick={handleChangeSettingsSubmit} 
                              size="large" 
                              className={classes.submitButton}>
                                <CheckIcon/>
                                Proceed
                              </Button>
                          </div>
                      </div>
                  </div>
              </div>
                                  
                <Button 
                onClick={() => setOpenSettingsModal(false)}
                className={classes.closeButton}
                >
                    X
                </Button >
            </div>
        </Modal>
          ) 
          : // if there is more than 1 layout 
          (
            layoutData && layoutData.length > 1 && openModal ? 
            <Modal
            open={openModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableAutoFocus
            >
              <div className={classes.modal}>
                  <Typography variant="body1" className={classes.paragraph}>
                  You have successfully created another <span className={classes.offcolour}>office layout! </span>
                  </Typography>
                  
                  <Typography variant="body1" className={classes.paragraph}>
                  Again, imagine what this office might look like in a few years time:
                  </Typography>
                  
                  {/* <Button
                  variant="contained"
                  size="large"
                  onClick={handleChangeSettings}
                  className={classes.submitButton}
                  >
                      <ArrowDownward />Simulate organisational changes
                  </Button> */}

                  <div className={classes.submitButtonNeg1Mid}>
                      <div className={classes.submitButton0}>
                          <div className={classes.submitButton1}>
                              <div className={classes.submitButton2}>
                                  <Button 
                                  variant="outlined" 
                                  color="primary"
                                  onClick={handleChangeSettings} 
                                  size="large" 
                                  className={classes.submitButton}>
                                    <ArrowDownward />Simulate organisational changes
                                  </Button>
                              </div>
                          </div>
                      </div>
                  </div>

                  <Typography variant="body1" className={classes.paragraph}>
                  Or generate the datasheet:
                  </Typography>

                  {/* <Button
                  variant="contained"
                  size="large"
                  onClick={handleGenerateDatasheet}
                  className={classes.submitButton}
                  >
                        <ArrowForward />Generate Datasheet
                  </Button> */}
                  
                  <div className={classes.submitButtonNeg1Mid}>
                      <div className={classes.submitButton0}>
                          <div className={classes.submitButton1}>
                              <div className={classes.submitButton2}>
                                  <Button 
                                  variant="outlined" 
                                  color="primary"
                                  onClick={handleGenerateDatasheet} 
                                  size="large" 
                                  className={classes.submitButton}>
                                    <ArrowForward />Generate Datasheet
                                  </Button>
                              </div>
                          </div>
                      </div>
                  </div>

                  <Button 
                  onClick={() => setOpenModal(false)}
                  className={classes.closeButton}
                  >
                      X
                  </Button >
              </div>
            </Modal> : 
                <Modal
                open={openSettingsModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                disableAutoFocus
                >
                <div className={classes.modal}>
                    <Typography variant="body1" className={classes.paragraph_reverse}>
                    <span className={classes.offcolour}>Simulate organisational changes</span>
                    </Typography>
                    
                    {/* // previous settings  */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1" className={classes.previousSettings}>
                        Previous Settings:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                      <Typography variant="body1" className={classes.previousSettings}>
                        {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['open_area_percent'])}% Open Space <br/>
                        {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['closed_area_percent'])}% Closed Space <br/>
                        {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['meeting_area_percent'])}% Meeting Space <br/>
                        {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['number_of_staff'])} Staff <br/>
                        {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['total_surface_area'])} square meters of Space <br/>
                        {Math.trunc(JSON.parse(layoutData[layoutData.length - 1]['layout'])['percent_of_permanent_staff'])}% Permanent staff <br/>
                      </Typography>
                      </Grid>
                    </Grid>

                    {/* // new settings */}
                    <div className={classes.helpDrawer}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.previousSettings}>
                          New Settings:
                          </Typography>
                        </Grid>
                      
                        <Grid item xs={6}>
                            <div className={classes.paper}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                    <Typography variant="body1" display="block" gutterBottom className={classes.task}>
                                        {/* A&#41; Adjust the Office Space Proportions */}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <PieChart
                                        defaultValue={ {
                                            settingsOfficeOpen: context.state.settingsOfficeOpen,
                                            settingsOfficeMeeting: context.state.settingsOfficeMeeting,
                                            settingsOfficeClosed: context.state.settingsOfficeClosed
                                        } }
                                        pieChartCallback={ handleCallbackPieChart }
                                        width={"220"}
                                        height={"220"}
                                        >
                                        </PieChart>
                                    </Grid>
                                </Grid>
                            </div>

                        </Grid>

                        <Grid item xs={6}>
                            <div className={classes.paper}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                      <Typography variant="body1" display="block" gutterBottom className={classes.task}>
                                        {/* B&#41; Configure the Office Space Occupancy */}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" display="block" gutterBottom className={classes.previousSettings}>
                                        How many people are in the space?
                                        </Typography>
                                        <TextField 
                                        type="number"
                                        value={ context.state.settingsConfigPeople }                            
                                        onChange={ handleToggleChangeOccupancyVal }
                                        fullWidth="true"
                                        hiddenLabel
                                        // label="How many people are in the space?"
                                        placeholder="A number"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" display="block" gutterBottom className={classes.previousSettings}>
                                        How many square meters is your space?
                                        </Typography>
                                        <TextField 
                                        type="number"
                                        value={ context.state.settingsConfigFloorArea }                            
                                        onChange={ handleToggleChangeOccupiedArea }
                                        // value={this.value}
                                        fullWidth="true"
                                        hiddenLabel
                                        // label="How many square meters is your space?" 
                                        placeholder="A number"
                                        />
                                        {
                                          (context.state.settingsConfigPeople / context.state.settingsConfigFloorArea > 0.1) && (
                                            <Paper style={{marginTop: 2, paddingTop: 4, backgroundColor: 'rgb(151, 199, 191)', color: '#000'}}>
                                                Not sufficient floor area 
                                            </Paper>
                                          )
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" display="block" gutterBottom className={classes.previousSettings}>
                                            What percentage of staff is permanent?
                                        </Typography>
                                        <Slider
                                            value={ context.state.settingsConfigOccupiedPercent }
                                            onChange={ handleToggleChangeOccupiedPercent }
                                            // getAriaValueText={value}
                                            aria-labelledby="discrete-slider"
                                            valueLabelDisplay="auto"
                                            step={10}
                                            marks
                                            min={0}
                                            max={100}
                                        />
                                    </Grid>
                                    

                                </Grid>
                            </div>
                        </Grid>
                      </Grid>
                    </div>         

                    {/* <Button
                    variant="contained"
                    size="large"
                    onClick={handleChangeSettingsSubmit}
                    className={classes.submitButton}
                    >
                        Proceed
                    </Button> */}
                    
                    <div className={classes.submitButtonNeg1Mid}>
                      <div className={classes.submitButton0}>
                          <div className={classes.submitButton1}>
                              <div className={classes.submitButton2}>
                                  <Button 
                                  variant="outlined" 
                                  color="primary"
                                  onClick={handleChangeSettingsSubmit} 
                                  size="large" 
                                  className={classes.submitButton}>
                                    <CheckIcon/>
                                    Proceed
                                  </Button>
                              </div>
                          </div>
                      </div>
                  </div>
                  
                    <Button 
                    onClick={() => setOpenSettingsModal(false)}
                    className={classes.closeButton}
                    >
                        X
                    </Button >
                </div>
            </Modal>
          )
          )
        }
      </>
  );
}