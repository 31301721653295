import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import Drawing from './components/Drawing';

import { MyProvider } from './components/settingsContext';
import './App.css';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Helvetica Neue LT Std',
      h1: { fontSize: 69 },
      h2: { fontSize: 57 },
      h3: { fontSize: 48 },
      h4: { fontSize: 40 },
      h5: { fontSize: 33 },
      h6: { fontSize: 28 },
      // subtitle1: { fontSize: 23 },
      // subtitle2: { fontSize: 19 },
      // body1: { fontSize: 19 },
      body2: { fontSize: 16 },
      
    },
    h6: {
      fontFamily: 'Helvetica Neue LT Std',
      fontSize: 19,
      fontWeight: 'bold',
    },
    body1: {
      fontFamily: 'Helvetica Neue LT Std',
      fontSize: 16,
      fontWeight: 'bold',
    },
    subtitle1: {
      fontFamily: 'Helvetica Neue LT Std',
      fontSize: 16,
      fontWeight: 'bold',
    },
    overline: {
      fontSize: 19,
      fontWeight: 'bold',
    },
    button: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
  }
});

function App() {
  return (
    <div className="App">
      {/* <ThemeProvider useSuspense={false}> */}
      <MuiThemeProvider theme={theme}>
        <MyProvider>
          <Switch>
              <Route exact path='/'>
                <Drawing/>
                {/* <Settings /> */}
                {/* <Home /> */}
              </Route>
              {/* <Route exact path='/settings'>
              </Route> */}
              {/* <Route exact path='/drawing'>
                <Drawing/>
              </Route> */}
            </Switch>
        </MyProvider>
      </MuiThemeProvider>
      {/* </ThemeProvider> */}
    </div>
  );
}

export default App;
