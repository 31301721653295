import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
    menu: {
        // height: "36.5px",
        width: "100%",
        // display: 'none'
    },
    paperSaveDrawing: {
        height: "100%",
        // width: "100%",
        // border: '2px solid'
        // float: 'left',
        position: 'relative',
    },
    paperSaveDrawing_outline: {
        // backgroundColor: "rgba(255, 99, 71, 0.15)"
        // border: '1rem solid #e5a76d'
    },
    image: {
        width: "100%",
    },
    image_outline: {
        width: "100%",
        border: '0.3rem solid #e5a76d',
    },
    imageCheckbox: {
        position: 'absolute',
         top: 0,
         right: 0,
    }
}));
