import React, { useState, useEffect, useContext } from 'react';
import { Grid, TextField, Checkbox, Button, Typography, Slider, MenuItem } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import useStyles from './datasheetmodal-styles';
import { MContext } from './settingsContext';
import LoadingOverlay from 'react-loading-overlay';
import countries from './countries';
import PdfGenerator from '../components/PdfGenerator';

export default function ({
    open
}) {
    const [openModal, setOpenModal] = useState();
    const [displayDatasheet, setDisplayDatasheet] = useState();
    const [isLoading, setIsLoading] = useState();
    const classes = useStyles();
    
    const context = useContext(MContext);

    useEffect(() => {
        if (open !== null) {
            setOpenModal(true);
            setDisplayDatasheet(false);
            setIsLoading(false);
        }
    }, [open])

    const handleHomeNameFirst = (e, value) => {
        const sc = {
            homeNameFirst: e.target.value
        }
        context.homeNameFirst(sc);
    }

    const handleHomeNameLast = (e, value) => {
        const sc = {
            homeNameLast: e.target.value
        }
        context.homeNameLast(sc);
    }

    const handleHomeEmail = (e, value) => {
        const sc = {
            homeEmail: e.target.value
        }
        context.homeEmail(sc);
    }

    const handleHomeCompany = (e, value) => {
        const sc = {
            homeCompany: e.target.value
        }
        context.homeCompany(sc);
    }

    const handleHomeCountry = (e, value) => {
        const sc = {
            homeCountry: e.target.value
        }
        context.homeCountry(sc);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setDisplayDatasheet(true);
        setIsLoading(true);
        sendDatasheet()
        .then(_ => {
            // setOpenModal(false);
            // setDisplayDatasheet(false);
            setIsLoading(false);
        })
        .catch(_ => {
            // error
            setIsLoading(false);
        })
        
    }

    const sendDatasheet = () => {
        return new Promise ((resolve, reject) => {
            // contact server
            const body = {
                nameFirst: context.state.homeNameFirst,
                nameLast: context.state.homeNameLast,
                email: context.state.homeEmail,
                company: context.state.homeCompany,
                country: context.state.homeCountry,
            };

            const url = 'https://e3ip7wkx3cypppslxnp5ph4aiq0sgrtn.lambda-url.ap-southeast-1.on.aws/';
            fetch(url, {
                method: "POST",
                body: JSON.stringify(body),
                headers : {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                resolve();
            })
            .catch(_ => {
                reject();
            })

        })
    }

    const handleDone = () => {
        setOpenModal(false);
    }

    const generateDatasheet = () => {
        const _imageData = context.state.imageData;
        const imageSelected = context.state.imageSelected;

        const pdfGenerator = new PdfGenerator();
        pdfGenerator.generatePdf2(_imageData, imageSelected, context)
        .then(url => {
            window.open(url);
            handleDone();
        })

    }

    const closeModel = () => {
        handleDone();
    }

    return (
        <Modal
            open={openModal}
            // onClose={() => setOpenModal(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={displayDatasheet ? classes.modalDisplayDatasheet : classes.modal}>
                {
                    isLoading 
                    ?
                    <LoadingOverlay
                        active={isLoading}
                        spinner
                        text='generating...'
                        className={classes.overlay}
                        >
                    </LoadingOverlay>
                    :
                    displayDatasheet ?
                    <div>
                        
                        <Typography variant="h5" className={classes.title}>
                                Datasheet
                        </Typography>

                        <Grid item direction="column" className={classes.contactdetails}>
                            <Typography variant="subtitle1" display="block" gutterBottom className={classes.task}>
                            A datasheet has been successfully generated for you.
                            </Typography>

                            <Typography variant="subtitle1" display="block" gutterBottom className={classes.task}>
                            You can view and download it below.
                            </Typography>

                            <Button>
                                <img src="image/pdf-icon.png" height={150} onClick={generateDatasheet} />
                            </Button>
                        </Grid>

                        <div >

                            <div className={classes.submitButtonNeg1}>
                                <div className={classes.submitButton0}>
                                    <div className={classes.submitButton1}>
                                        <div className={classes.submitButton2}>
                                            <Button
                                            variant="outlined" 
                                            color="primary"
                                            onClick={generateDatasheet} 
                                            size="large" 
                                            className={classes.submitButton}>
                                                Open Datasheet
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <Button 
                            onClick={ closeModel }
                            className={classes.closeButton}
                            >
                                X
                            </Button >
                        </div>
                    </div>
                    :
                    <div>
                        <Typography variant="h5" className={classes.title}>
                            Datasheet
                        </Typography>
                        <Grid item direction="column" className={classes.contactdetails}>
                            <Typography variant="subtitle1" display="block" gutterBottom className={classes.task}>
                            Please enter your contact details
                            </Typography>
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Grid item>
                                    <TextField id="filled-basic" label="First Name" defaultValue={ context.state.homeNameFirst } onChange={ handleHomeNameFirst } className={classes.textinput} required />
                                </Grid>
                                <Grid item>
                                    <TextField id="filled-basic" label="Last Name" defaultValue={ context.state.homeNameLast } onChange={ handleHomeNameLast } className={classes.textinput} required />
                                </Grid>
                                <Grid item>
                                <TextField id="filled-basic" label="Email" defaultValue={ context.state.homeEmail } onChange={ handleHomeEmail } className={classes.textinput} required />
                                </Grid>
                                <Grid item>
                                    <TextField id="filled-basic" label="Company" defaultValue={ context.state.homeCompany } onChange={ handleHomeCompany } className={classes.textinput} />
                                </Grid>
                                <Grid item>
                                    <TextField id="filled-basic" select label="Country" defaultValue={ context.state.homeCountry } onChange={ handleHomeCountry } className={classes.textinput}  required >
                                    {countries.map((option) => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                    </TextField>
                                </Grid>
                                <Typography variant="caption" display="block" gutterBottom className={classes.agreement}>
                                    By submitting, I agree to the information entered being used for the purpose of contacting me.
                                    <br/>
                                    Disclaimer: This process does not aim to replace project planning and must not be used as a design brief.
                                </Typography>

                                <div className={classes.submitButtonNeg1}>
                                    <div className={classes.submitButton0}>
                                        <div className={classes.submitButton1}>
                                            <div className={classes.submitButton2}>
                                                <Button 
                                                type="submit"
                                                variant="outlined" 
                                                color="primary"
                                                // onClick={handleChangeSettingsSubmit} 
                                                size="large" 
                                                className={classes.submitButton}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Button 
                                onClick={ closeModel }
                                className={classes.closeButton}
                                >
                                    X
                                </Button >
                            </form>
                        </Grid>
                    </div>
                }
            </div>
        </Modal>
    )
}