import React, { useState, useContext } from 'react';
// import Grid from '@material-ui/core/Grid';
import useStyles from './datasheetsettings-styles';
import Drawer from '@material-ui/core/Drawer';
import { ExpandMore, ExpandLess, CloudUpload, ArrowForward, ArrowBack, Help, Done } from '@material-ui/icons';
import handleNavigationDrawer from './hooks/handleNavigationDrawer';
import { Grid, TextField, Checkbox, Button, Typography, Slider } from '@material-ui/core';

import 'fontsource-roboto';

export default function({handleClickGenerate, selectedImagesCount}) {
  
    const classes = useStyles();

    // const handleClick = () => {

    // }

    return (
        <>
            {/* <Button onClick={handleClickGenerate} className={classes.drawerButton}>
                <ArrowForward/>
                Select up to two layouts to include in your datasheet. Click here to generate.
            </Button> */}
            {/* <Button
            variant="contained"
            size="large"
            onClick={handleClickGenerate}
            className={classes.submitButton}
            disabled={(selectedImagesCount < 2)}
            >
                {
                    (selectedImagesCount < 1) && 
                        "Select two layouts to proceed"
                }
                {
                    (selectedImagesCount === 1) && 
                        "Select one more layout to proceed"
                }
                {
                    (selectedImagesCount > 1) && 
                        "proceed"
                }
            </Button> */}

            <div className={classes.submitButtonNeg1}>
                <div className={classes.submitButton0}>
                    <div className={classes.submitButton1}>
                        <div className={classes.submitButton2}>
                            <Button 
                            variant="outlined" 
                            color="primary"
                            onClick={handleClickGenerate} 
                            size="large" 
                            className={classes.submitButton}
                            disabled={(selectedImagesCount < 2)}
                            >
                                {
                                    (selectedImagesCount < 1) && 
                                        "Select two layouts to proceed"
                                }
                                {
                                    (selectedImagesCount === 1) && 
                                        "Select one more layout to proceed"
                                }
                                {
                                    (selectedImagesCount > 1) && 
                                        "proceed"
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}