import React, { useState, useEffect, useContext } from 'react';
import { Grid, TextField, Checkbox, Button, Typography, Slider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import { MContext } from './settingsContext';
import useStyles from './datasheet-styles';
// export default class Layout extends React.Component {
export default function({setSelectedImagesCount}) {

    const [imageSelect, useImageSelect] = useState();

    // static contextType = MContext;
    const context = useContext(MContext);
    
    const classes = useStyles();
    // generateDatasheet = () => {

    //     // const layout_summary = this.state.stringDataPool;
    //     // const layout_summary_unpack = JSON.parse(layout_summary[0])
    //     // const image = this.context.state.imageData;

    //     // layout_summary_unpack["image"] = image;
    //     const settingsContext = this.context;
    //     const _imageData = settingsContext.state.imageData;
    //     const pdfGenerator = new PdfGenerator();
    //     pdfGenerator.generatePdf(_imageData)
    //     .then(url => {
    //         window.open(url);
    //     })
    // }

    useEffect(() => {
        const imageData = context.state.imageData;
        const startdata = imageData.map((img, i) => {
            return {
                index: i, 
                clicked: false,
                image: img["image"],
            }
        })
        console.log(startdata)
        useImageSelect(startdata);
    }, []);

    const selectedImage = (i) => {
        var _imageSelect = imageSelect.slice();
        // check if max limit

        const numSelect = _imageSelect.filter(i => i.clicked).length;
        
        if (_imageSelect[i].clicked) {
            _imageSelect[i].order = null;

            // reduce other item by 1
            for (var j = 0; j < _imageSelect.length; j++) {
                if (_imageSelect[j].clicked) {
                    if (_imageSelect[j].order === 2) {
                        _imageSelect[j].order = 1;
                    } 
                }
            }
        } else {
            if (numSelect === 0) {
                _imageSelect[i].order = 1;
            }
            if (numSelect === 1) {
                _imageSelect[i].order = 2;
            }
        }    
        
        _imageSelect[i].clicked = (numSelect < 2) ? !_imageSelect[i].clicked : false;

        const settingsContext = context;
        const sc = {
            imageSelected: _imageSelect
        }
        settingsContext.imageSelected(sc);
        
        useImageSelect(_imageSelect);
        // const _imageSelected = this.state.imageSelected;
        // if (i in _imageSelected) {
        //     _imageSelected
        // }
        var _imageSelect2 = imageSelect.slice();
        const numSelect2 = _imageSelect2.filter(i => i.clicked).length;
        setSelectedImagesCount(numSelect2);
    }
    
    const imageReturn = (img) => {
        return ( // elevation={img.clicked ? 3 : 0} 
            <Paper className={classes.paperSaveDrawing} square={true} onClick={() => selectedImage(img.index)} key={"imagePaper-" + img.index} >
                <img key={"image-" + img.index} src={img.image} className={classes.image}/>
                {
                    img.order && 
                    <Typography variant="body1" style={{fontSize: '0.8rem', top: 0, right: '2em', position: 'absolute'}}>
                        Option {img.order}
                    </Typography>
                }
                <input type="checkbox" className={classes.imageCheckbox} id="check1" checked={img.clicked} />
            </Paper>
        )
    }

    return (
        <>
            {
                imageSelect ?
                (   
                    // <Grid item className={classes.menu} style={{height:"100%"}}>
                    <Grid item className={classes.menu} >
                        <Grid container alignItems="stretch" style={{overflowY:"scroll", height:"100%"}}>
                            {
                                imageSelect.map((img) => {
                                    return (
                                        <Grid item xs={6} style={{padding: 2}}>
                                            {imageReturn(img)}
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                ) 
                : 
                <></>
            }
        </>
    );
}
