import { useState, useEffect } from 'react';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

export default () => {
  // const screenMd = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  const toggleDrawerOpen = () => setOpen(!open);

  // useEffect(() => {
  //   setOpen(!screenMd);
  // }, [screenMd]);

  return { toggleDrawerOpen, open };
};
