import React from 'react';
import { Link } from "react-router-dom";
import { Grid, TextField, Checkbox, Button, Typography, Slider } from '@material-ui/core';
import { withStyles  } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Paper from '@material-ui/core/Paper';
import 'fontsource-roboto';
import HelpIcon from '@material-ui/icons/Help';
import DownloadIcon from '@material-ui/icons/GetApp';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import GestureIcon from '@material-ui/icons/Gesture';
import CheckIcon from '@material-ui/icons/Check';
import LoadingOverlay from 'react-loading-overlay';
import { Steps } from 'intro.js-react';

import { MContext } from './settingsContext';
import Layout from "./Layout";
import TipsModal from './tips';
import ViewerContainer from './ViewerContainer';
import store from './store';
import solve from './compute';

import Settings from './Settings';
import Proceed from './Proceed';
import Datasheet from "./Datasheet";
import DataSheetSettings from "./DataSheetSettings";
import DatasheetModal from './DatasheetModal';

import "intro.js/introjs.css";

const useStyles = theme => ({
    root: {
        height: '100%',
        flexGrow: 1,
        // padding: theme.spacing(4),
        backgroundColor: "#465767"
	},
	title: {
        // height: "10%",
		textAlign: "left",
		color: "#fff",
        paddingRight: "16px",
    },
    menu: {
        height: "36.5px",
        width: "100%",
        // display: 'none'
    },
    tool: {
        // height: "10%",
        whiteSpace: "nowrap"
    },
    task: {
        textAlign: "left",
    },
    stretch: { 
        height: "100%"
    },
    drawing: {
        // display: "flex", 
        // flexDirection: "column",
        height: 'calc(100% - 36.5px - 36.5px)',
        width: '100%',
    },
    paper: {
        padding: theme.spacing(1),
    },
    paperLarge: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(0),
        backgroundColor: "#465767",
    },
    control: {
        // padding: theme.spacing(2),
    },
    layout: {
        // height: '80%',
        width: '100%',
    },
    mesh: {
        // height: '90%',
        width: '100%',
    },
    overlay: {
        height: '100%',
    },
    buttonContainer: {
        marginBottom: '12px',
    },
    paperSaveDrawing: {
        height: '140px',
        width: '140px',
        padding: theme.spacing(0),
        marginTop: '16px',
        marginRight: '8px',
    },
    snapshot: {
    },
    nospan: {
        lineHeight: 0
    },
    proceedButton: {
        width: '100%',
        fontStyle: 'italic',
        backgroundColor: '#97C7BF',
        '&:hover': {
          backgroundColor: '#7ba8a1',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: '#7ba8a1',
          },
        }
    },
    generateButton: {
        borderRadius:50, 
        position: 'absolute', 
        bottom: 18, 
        right: 18, 
        backgroundColor:'#97C7BF',
        '&:hover': {
            backgroundColor: '#7ba8a1',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: '#7ba8a1',
            },
        }
    },
    helpButton: {
        borderRadius:50, 
        position: 'absolute', 
        top: 18, 
        right: 18, 
        // backgroundColor:'#e5a76d',
        '&:hover': {
            backgroundColor: '#f1efef',
        }
    },
    miniature: {
        position: 'absolute',
        left: '0.5em',
        top: '0.5em',
    },
    introSettingsClass: {

    },
    clestraClass: {
        fontFamily: 'Helvetica Neue LT Std',
    },
    submitButton: {
        borderRadius: 0, 
        border: 'none',
        '&:hover': {
            border: 'none',
        },
        backgroundColor:'#e5a76d',
        fontWeight: 'bold',
    },
    submitButtonNeg1: {
        position: 'absolute', 
        bottom: 18, 
        right: 18, 
    },
    submitButton0: {
        border: "1px solid grey", 
        marginTop: '1em', 
        width: 'fit-content', 
        height: 'fit-content', 
    },
    submitButton1: {
        backgroundColor: "rgb(151, 199, 191)", 
        position: 'relative', 
        right: -6, 
        bottom: 8,
        '&:hover': {
            right: -2, 
            bottom: 4,
            transition: '0.25s',
            backgroundColor: 'rgb(151 199 191 / 30%)',
        },
    },
    submitButton2: {
        position: 'relative', 
        left: 8, 
        top: 4,
        '&:hover': {
            left: 2, 
            // bottom: 2,
            transition: '0.25s'
        },
    },
    helpButton: {
        borderRadius:0, 
        border: 'none',
        backgroundColor: 'none',
        fontWeight: 'bold',
        '&:hover': {
            border: 'none',
        }
    },
    helpButtonNeg1: {
        position: 'absolute', 
        top: 18, 
        right: 18, 
    },
    helpButton0: {
        border: "1px solid grey", 
        marginTop: '1em', 
        width: 'fit-content', 
        height: 'fit-content', 
    },
    helpButton1: {
        backgroundColor: "rgb(151, 199, 191)", 
        position: 'relative', 
        right: 12, 
        bottom: 8,
        '&:hover': {
            right: -2, 
            bottom: 4,
            transition: '0.25s',
            backgroundColor: 'rgb(151 199 191 / 30%)',
        },
    },
    helpButton2: {
        position: 'relative', 
        left: 8, 
        top: 4,
        border: '3px solid #e5a76d',
        '&:hover': {
            left: 2, 
            transition: '0.25s',
            border: 'none',
        },
    },
});

class Drawing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showResults: false,
            meshData: [],
            meshDataPool: [],
            stringDataPool: null,
            generateLayoutDisabled: false,
            isLoading: false,
            generateDataSheetDisabled: true,
            openModal: null,
            openProceed: null,
            openDataSheet: null,
            openDatasheetModal: null, // set true to open at startup, otherwise default is null
            selectedImagesCount: null,
            stepsEnabled: false,
            initialStep: 0,
            steps: [
                {
                    element: ".introSettings", // inside settings.js file
                    intro: "Start by configuring a new layout here. You can adjust the number of people, floor area, and even upload an image of the floorplan!",
                    position: "top",
                    disableInteraction: true,
                    tooltipClass: 'clestraClass',
                },
                {
                    element: ".toolbar", 
                    intro: "Next, use the drawing tool to draw a new layout on the whiteboard. You can also add a reception area.",
                    position: "right",
                    disableInteraction: true,
                    tooltipClass: 'clestraClass',
                },
                {
                    element: ".introGenerate",
                    intro: "Finally, when you are happy with your drawing, you can generate a layout here.",
                    position: "left",
                    disableInteraction: true,
                    tooltipClass: 'clestraClass',
                },
                {
                    element: ".introInstructions",
                    intro: "Click here if you need to see these instructions again.",
                    position: "left",
                    disableInteraction: true,
                    tooltipClass: 'clestraClass',
                },
            ],
        }
    }

    static contextType = MContext;

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    generateLayout = (type) => {
        this.setState({
            generateLayoutDisabled: true,
            isLoading: true,
            generateDataSheetDisabled: true,
            // saveLayoutDisabled: true
        });
        let js_data = store.getState().toJS();

        // remove scene history ?

        const settingsContext = this.context;
        js_data['settings'] = {
            "settingsConfigFloorArea": settingsContext.state.settingsConfigFloorArea,
            "settingsConfigOccupiedPercent": settingsContext.state.settingsConfigOccupiedPercent,
            "settingsConfigPeople": settingsContext.state.settingsConfigPeople,
            "settingsOfficeClosed": settingsContext.state.settingsOfficeClosed,
            "settingsOfficeMeeting": settingsContext.state.settingsOfficeMeeting,
            "settingsOfficeOpen": settingsContext.state.settingsOfficeOpen,
            "settingsShape": settingsContext.state.settingsShape,
            "randomString": Math.random().toString(36) + new Date().toLocaleString()
        }
        
        let js_string = JSON.stringify(js_data);
        
        // console.log(js_string);

        // if no layout data then return
        if (Object.keys(js_data['react-planner']['scene']['layers']['layer-1']['areas']).length < 1) {
            console.log('no areas drawn');
            this.setState({
                generateLayoutDisabled: false,
                isLoading: false,
                generateDataSheetDisabled: true,
            })
            return;
        }

        solve(js_string).then((dataReturn) => {
            if (dataReturn 
                && "mesh_data" in dataReturn 
                && "string_data" in dataReturn 
                && dataReturn["mesh_data"] 
                && dataReturn["string_data"]
                ) {
                const meshDataReturn = dataReturn["mesh_data"];
                const stringDataReturn = dataReturn["string_data"];
                
                const newImageData = settingsContext.state.imageData;
                if (type === "changeSettings") {
                    // instead of pushing to stack
                    newImageData.push({"layout": stringDataReturn});
                    const sc = {
                        imageData: newImageData
                    }
                    settingsContext.imageData(sc);
                }

                if (type === "newLayout") {
                    // replace previous
                    // newImageData = 
                    const sc = {
                        imageData: [{"layout": stringDataReturn}]
                    }
                    settingsContext.imageData(sc);
                }
                

                this.setState({
                    meshDataPool: meshDataReturn,
                    stringDataPool: stringDataReturn,
                    showResults: true,
                    isLoading: false,
                    generateDataSheetDisabled: false,
                })
            } else {
                this.setState({
                    generateLayoutDisabled: false,
                    isLoading: false,
                    generateDataSheetDisabled: true,
                })
            }
            this.interval = setInterval(this.addMeshToRender.bind(this), 1);
        })    
    }

    saveLayout = () => {
        const settingsContext = this.context;
        this.setState({
            // saveLayoutExist: true,
            // saveLayoutDisabled: true
        })
    }

    stopRendering = () => {
        this.setState({
            generateDataSheetDisabled: false,
            openProceed: true,
        });
        clearInterval(this.interval); // stop the rerendering
        console.log("render done");
    }

    addMeshToRender = () => {
        // clear any existing maesh image
        this.setState({
            generateDataSheetDisabled: true,
            meshData: [],
        }, () => {
            // drop in the new mesh image
            this.setState({
                // generateDataSheetDisabled: true,
                meshData: this.state.meshDataPool,
            }, () => {
                // finish
                // this.setState({
                //     generateDataSheetDisabled: false,
                //     openProceed: true,
                // });
                // clearInterval(this.interval); // stop the rerendering
                // console.log("render done");
            })
        })


        // let meshPoolCount = this.state.meshDataPool.length;
        
        // const limit =  80 + Math.pow(meshPoolCount/ 15, 1.4);
        // // const limit =  1000000000;
        // this.setState({
        //     generateDataSheetDisabled: true
        // });

        // if (meshPoolCount > 0) {
        //     const newMeshData = this.state.meshData.concat(this.state.meshDataPool.slice(0, limit));
        //     const newmeshDataPool = this.state.meshDataPool.slice(limit);
        //     this.setState({
        //         meshData: newMeshData,
        //         meshDataPool: newmeshDataPool
        //     });
        // } else {
        //     const lastMeshData = this.state.meshData.concat(this.state.meshData[0]);
        //     this.setState({
        //         meshData: lastMeshData,
        //         generateDataSheetDisabled: false,
        //         openProceed: true,
        //     });
        //     clearInterval(this.interval); // stop the rerendering
        //     console.log("render done");
        // }
    }

    selectedImage = (i) => {
        // const _imageSelected = this.state.imageSelected;
        // if (i in _imageSelected) {
        //     _imageSelected
        // }
    }

    triggerOpenModal = () => {
        this.setState({
            openModal: !this.state.openModal
        });
    }

    handleGoBack = () => {
        this.setState({
            showResults: false,
            meshData: [],
            meshDataPool: [],
            stringDataPool: null,
            generateLayoutDisabled: false,
            isLoading: false,
            generateDataSheetDisabled: true,
            openModal: null,
            openProceed: null,
            openDataSheet: null,
        })
    }

    handleGoForward = () => {
        this.setState({
            showResults: false,
            meshData: [],
            meshDataPool: [],
            stringDataPool: null,
            generateLayoutDisabled: true,
            isLoading: false,
            generateDataSheetDisabled: true,
            openModal: null,
            openProceed: null,
            openDataSheet: true,
        })
    }

    handleGoBackAndGenerateLayout = () => {
        this.handleGoBack();
        this.generateLayout("changeSettings");
    }

    handleClickGenerate = () => {
        this.setState({
            openDatasheetModal: this.state.openDatasheetModal ? !this.state.openDatasheetModal : true,
        })
    }

    getMiniature = () => {
        const settingsContext = this.context;
        const newImageData = settingsContext.state.imageData;
        if (newImageData && newImageData.length > 1) {
            // changing the settings  in modal hits this function
            // this lags the settings piechart in modal
            // console.log(newImageData)
            const previousImage = newImageData[newImageData.length - 2];
            return (
                <>
                    <img src={previousImage.image} style={{width: "16em"}}/>
                    <Typography variant="body1">
                        Previous Layout
                    </Typography>
                </>
            )
        }        
    }

    handleGoMain = () => {
        // confirm message before going to main page
        window.open('/', "_self");
    }

    setSelectedImagesCount = (count) => {
        this.setState({
            selectedImagesCount: count
        });
    }

    handleStartIntro = () => {
        this.setState({ stepsEnabled: true });
    }

    handleStopIntro = () => {
        this.setState({ stepsEnabled: false });
    };

    render() {
        const { classes } = this.props;
        const { 
            showResults, 
            meshData, 
            stringDataPool, 
            generateLayoutDisabled, 
            isLoading, 
            generateDataSheetDisabled, 
            openModal, 
            openProceed, 
            openDataSheet, 
            openDatasheetModal, 
            selectedImagesCount,
            stepsEnabled,
            steps,
            initialStep,
        } = this.state;
        
        // const settingsContext = this.context;
        // const imageData = settingsContext.state.imageData;

        return (
            <Grid container className={classes.root} direction="column">
                <Steps
                enabled={stepsEnabled}
                steps={steps}
                initialStep={initialStep}
                onExit={this.handleStopIntro}
                options={{ 
                    hideNext: false,
                    hidePrev: true,
                    showBullets: false,
                }}
                />
                
                <Grid item className={classes.menu}>
                    <Grid container direction="row">
                        <Grid item className={classes} >
                            <Button onClick={this.handleGoMain}>
                                <Typography variant="h5" style={{color:"#fff"}}>Modular Planner
                            <img src={"/image/clestra.png"} height={18} style={{marginLeft: 16}}/>                                
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item className={classes.title} style={{position:'absolute', bottom: '0px', padding: "6px"}}>
                            <Typography variant="paragraph" style={{color:"#fff"}}>Proudly made by YKS Group</Typography>
                        </Grid>
                        {/* <Grid item className={classes.tool}>

                                <Grid container direction="row" className={classes.buttonContainer} spacing={1}>
                                    <Grid item>
                                        <Button variant="contained" onClick={this.generateLayout} disabled={generateLayoutDisabled} size="small"><CheckIcon/>Generate Layout</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={this.triggerOpenModal} disabled={generateLayoutDisabled} size="small"><HelpIcon/>Instructions</Button>
                                    </Grid>
                                </Grid>

                        </Grid> */}
                    </Grid>
                </Grid>
                

                <Grid item className={classes.drawing}>
                    <Paper className={classes.paperLarge} elevation={0}>
                        {
                            isLoading
                            ?
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text='Calculating... This might take several minutes'
                                className={classes.overlay}
                                >
                            </LoadingOverlay>
                            :
                            (
                                showResults
                                ? 
                                <>
                                    <ViewerContainer 
                                    className={classes.mesh} 
                                    id="mesh-id"
                                    meshToChild = {meshData}
                                    stringDataPool = {stringDataPool}
                                    stopRendering={this.stopRendering}
                                    >
                                        {/* // show previous results */}
                                        
                                    </ViewerContainer>
                                    <Paper id="miniature" square={true} className={classes.miniature}>
                                        {this.getMiniature()}
                                    </Paper>
                                </>
                                : 
                                (
                                    openDataSheet ?
                                    <Datasheet className={classes.layout} setSelectedImagesCount={this.setSelectedImagesCount} id="datasheet-id"></Datasheet>
                                    :
                                    <>
                                        <Layout className={classes.layout} id="layout-id"></Layout>
                                        
                                        <div className={classes.submitButtonNeg1}>
                                            <div className={classes.submitButton0}>
                                                <div className={classes.submitButton1}>
                                                    <div className={classes.submitButton2}>
                                                        <Button 
                                                        variant="outlined" 
                                                        color="primary"
                                                        onClick={() => this.generateLayout("newLayout")} 
                                                        disabled={generateLayoutDisabled} 
                                                        size="large" 
                                                        className={classes.submitButton}
                                                        >
                                                            <CheckIcon/>
                                                            <div class="introGenerate">
                                                                Generate Layout
                                                            </div>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className={classes.helpButtonNeg1}>
                                            <div className={classes.helpButton0}>
                                                <div className={classes.helpButton1}>
                                                    <div className={classes.helpButton2}>
                                                        <Button 
                                                        variant="outlined" 
                                                        color="primary"
                                                        onClick={() => this.triggerOpenModal()}
                                                        size="large" 
                                                        className={classes.helpButton}>
                                                            <HelpIcon/>
                                                            <div class="introInstructions">
                                                                Instructions
                                                            </div>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            )
                        }
                    </Paper>
                </Grid>

                {
                    // imageData ?
                    // (   
                    //     <Grid item className={classes.menu}>
                    //         <Grid container direction="row">
                    //             {
                    //                 imageData.map((img, i) => {
                    //                     return (
                    //                         <Paper className={classes.paperSaveDrawing} onClick={() => this.selectedImage({i})}>
                    //                             {/* <span className="nospan">{i}</span> */}
                    //                             <img src={img["image"]} width={140} />
                    //                         </Paper>
                    //                     )
                    //                 })
                    //             }
                    //         </Grid>
                    //     </Grid>
                    // ) : <></>
                }
                {(
                    generateLayoutDisabled
                    ?
                        openProceed ? 
                        <Proceed 
                        goBack={this.handleGoBack}
                        goForward={this.handleGoForward}
                        // context={this.context}
                        goBackAndGenerateLayout={this.handleGoBackAndGenerateLayout}
                        /> 
                        :
                        (
                            openDataSheet ?
                            <DataSheetSettings 
                            handleClickGenerate={this.handleClickGenerate}
                            selectedImagesCount={selectedImagesCount}
                            />
                            :
                            <></>
                        )
                    :
                    <Settings />
                )}
                
            <TipsModal handleOpenModal={openModal} handleStartIntro={this.handleStartIntro}/>
            <DatasheetModal open={openDatasheetModal} />
            </Grid>
        )
    }
}


export default withStyles(useStyles)(Drawing)