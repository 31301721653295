import {Catalog} from 'react-planner-fork';
import * as Areas from './areas/area/planner-element.jsx';
import * as Lines from './lines/wall/planner-element.jsx';
import Holes from './holes/door/planner-element.jsx';
import Items from './items/image/planner-element.jsx';
// import * as Items from './items/**/planner-element.jsx';

let catalog = new Catalog();

for( let x in Areas ) catalog.registerElement( Areas[x] );
for( let x in Lines ) catalog.registerElement( Lines[x] );
catalog.registerElement( Holes );
catalog.registerElement( Items );
// for( let x in Items ) catalog.registerElement( Items[x] );

// catalog.registerCategory('windows', 'Windows', [Holes.window, Holes.sashWindow, Holes.venetianBlindWindow, Holes.windowCurtain] );
// catalog.registerCategory('doors', 'Doors', [Holes.door] );

export default catalog;
