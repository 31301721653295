// import rhino3dm from 'rhino3dm';
import React from 'react';
import { MContext } from './settingsContext';
// import reactDomProductionMin from 'react-dom/cjs/react-dom.production.min';
// const rhino3dm = require('rhino3dm');
// const rhino3dm_module = require('rhino3dm');
const THREE = require('three');

// BOILERPLATE //

var _threeMesh = []; // maybe convert to state
let _threeMaterial; // maybe convert to state

// var controls;
var scene = new THREE.Scene();
const w = 15;
const h = 7.5;
var camera = new THREE.OrthographicCamera( -w, w, h, -h, 1, 1000 )
// var camera = new THREE.PerspectiveCamera( 45, window.innerWidth/window.innerHeight, 1, 1000 )
var renderer = new THREE.WebGLRenderer({
    // https://stackoverflow.com/questions/26193702/three-js-how-can-i-make-a-2d-snapshot-of-a-scene-as-a-jpg-image
    preserveDrawingBuffer: true,
    antialias: true
}) 
  
function meshToThreejs (mesh, material) {
    let loader = new THREE.BufferGeometryLoader()
    var geometry = loader.parse(mesh.toThreejsJSON())
    return new THREE.Mesh(geometry, material)
}

export default class Viewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mesh: []
        }
    }

    static contextType = MContext;

    componentWillMount() {
    }

    componentDidMount() {
        // Rhino models are z-up, so set this as the default
        THREE.Object3D.DefaultUp = new THREE.Vector3( 0, 0, 1 );

        scene.background = new THREE.Color(255,255,255);
        // scene.background = new THREE.Color( 0xff0000 );
        // var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
        // var renderer = new THREE.WebGLRenderer();
        // renderer.setSize( window.innerWidth, window.innerHeight );
        // const windowRatio = window.innerWidth/window.innerHeight;
        
        renderer.setPixelRatio( window.devicePixelRatio );
        // renderer.setSize( window.innerWidth, window.innerHeight );

        const stringDataPool = this.props.stringDataPool;
        const rhMetaData = JSON.parse(stringDataPool[0]);
        const rhWidth = rhMetaData["image_width"];
        const rhHeight = rhMetaData["image_height"];

        const windowWidth = this.props.width;
        const windowHeight = this.props.height;

        const windowRatio = windowWidth/windowHeight;
        const rhRatio = rhWidth / rhHeight;

        if (rhRatio > windowRatio) {
            renderer.setSize(windowWidth, rhHeight * (windowWidth / rhWidth));
        } else {
            renderer.setSize(rhWidth * (windowHeight / rhHeight), windowHeight);
        }

        this.mount.appendChild( renderer.domElement );
        
        var geometry = new THREE.BoxGeometry( 1, 1, 1 );
        var material = new THREE.MeshStandardMaterial( { color: 0x7e31eb } );
        var cube = new THREE.Mesh( geometry, material );
        // scene.add( cube );
        const light = new THREE.HemisphereLight( 0xffffbb, 0x080820, 1 );
        // scene.add( light );

        // controls = new OrbitControls( camera, renderer.domElement  )
        camera.position.z = 230
        window.addEventListener( 'resize', this.onWindowResize, false );
        this.animate();
    }

    async componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed

        if (prevProps.meshToChild !== this.props.meshToChild) {
            await this.updateMesh();
            // hope this fix white screen bug
            renderer.render( scene, camera );
            await this.saveImage();
            this.props.stopRendering();
        }
    }

    animate = () => {
        // .setAnimationLoop()
        requestAnimationFrame( this.animate );
        renderer.render( scene, camera );
    }
    
    onWindowResize = () => {
        const stringDataPool = this.props.stringDataPool;
        const rhMetaData = JSON.parse(stringDataPool[0]);
        const rhWidth = rhMetaData["image_width"];
        const rhHeight = rhMetaData["image_height"];

        const windowWidth = this.props.width;
        const windowHeight = this.props.height;

        const windowRatio = windowWidth/windowHeight;
        const rhRatio = rhWidth / rhHeight;

        camera.aspect = rhRatio;
        camera.updateProjectionMatrix();
        
        if (rhRatio > windowRatio) {
            renderer.setSize(windowWidth, rhHeight * (windowWidth / rhWidth));
        } else {
            renderer.setSize(rhWidth * (windowHeight / rhHeight), windowHeight);
        }
        
        // renderer.setSize( this.props.width, this.props.height );
    }

    updateCameraSettings = (w, h, w2, h2) => {
        // this function zoom of the camera to the extents of the layout
        // disabling this will mean large layouts will extend past the frame
        const screenRatio = w2 / h2;
        const rhRatio = w / h;
        const offset = 0.5;

        // if (rhRatio > 1) {
        if (rhRatio > screenRatio) {
            camera.left = -1 * (w / 2 + offset);
            camera.right = w / 2 + offset;
            const h3 = h * (rhRatio / screenRatio);
            // incorrect when inversed
            // camera.top = h3 / 2 + offset; 
            // camera.bottom = -1 * (h3 / 2 + offset);
            camera.top = h / 2 + offset; 
            camera.bottom = -1 * (h / 2 + offset);
        } else {
            camera.top = h / 2 + offset;
            camera.bottom = -1 * (h / 2 + offset);
            const w3 = w * (screenRatio / rhRatio);
            // incorrect
            // camera.left = -1 * (w3 / 2 + offset);
            // camera.right = w3 / 2 + offset;
            camera.left = -1 * (w / 2 + offset);
            camera.right = w / 2 + offset;
        }   

        camera.updateProjectionMatrix();
    }

    saveImage = async () => {
        // save scene to image
        console.log('function saveImage')
        async function saveImageInside(ctx)  {
            const strMime = "image/png";
            const imgData = renderer.domElement.toDataURL(strMime);
            
            const newImageData = ctx.state.imageData;
            console.log(newImageData)
            console.log(imgData)
            console.log('saving image to ctx' , newImageData.length - 1)
            newImageData[newImageData.length - 1]["image"] = imgData;
            const sc = {
                imageData: newImageData
            }
            await ctx.imageData(sc);
        }
        
        const settingsContext = this.context;
        // window.setTimeout(saveImageInside.bind(null, settingsContext), 100);
        await saveImageInside(settingsContext);
    }

    updateMesh = async () => {
        console.log('function updateMesh')
        const { meshToChild, stringDataPool, width, height } = this.props;      
        const rhino3dm = window.rhino3dm;
        
        const rhMetaData = JSON.parse(stringDataPool[0]);
        const rhWidth = rhMetaData["image_width"];
        const rhHeight = rhMetaData["image_height"];

        this.updateCameraSettings(rhWidth, rhHeight, width, height);

        if (meshToChild && rhino3dm && rhino3dm.CommonObject && rhino3dm.CommonObject.decode) {
            // delete old mesh

            var temp_threeMesh = _threeMesh;
            var new_threeMesh = [];
            for (var i = 0; i < temp_threeMesh.length; i++) {
                scene.remove(temp_threeMesh[i]);
                temp_threeMesh[i].geometry.dispose();
            }

            // add new mesh
            for (var i = 0; i < meshToChild.length; i++) {
                let mesh = rhino3dm.CommonObject.decode(meshToChild[i]);
                if (!_threeMaterial) {
                    _threeMaterial = new THREE.MeshBasicMaterial({vertexColors:true, side:2})
                }
                let threeMesh = meshToThreejs(mesh, _threeMaterial);
                mesh.delete();
                // replaceCurrentMesh(threeMesh);
                
                new_threeMesh.push(threeMesh);
                scene.add(threeMesh);
            }
            
            _threeMesh = new_threeMesh
        }
    }

    render(){
        return (
            <div ref={ref => (this.mount = ref)} />
        );
    }
}
  