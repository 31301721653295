import React from 'react';
import Viewer from './Viewer';
import ContainerDimensions from 'react-container-dimensions';

export default class ViewerContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    

    render() {
        const {meshToChild, stringDataPool, stopRendering} = this.props;

        return (
            <ContainerDimensions>
                {
                    ({width, height}) => 
                    <Viewer 
                    meshToChild={meshToChild} 
                    stringDataPool={stringDataPool}
                    width={width}
                    height={height}
                    stopRendering={stopRendering}
                    />
                }

            </ContainerDimensions>
        );
    }
}