import React, { useEffect, useContext } from 'react';

// import {Map} from 'immutable';
// import {createStore} from 'redux';
import {Provider} from 'react-redux';

//download this demo catalog https://github.com/cvdlab/react-planner/tree/master/demo/src/catalog
import MyCatalog from '../catalog/mycatalog';
import ContainerDimensions from 'react-container-dimensions';

import {
  // Models as PlannerModels,
  // reducer as PlannerReducer,
  ReactPlanner,
  Plugins as PlannerPlugins,
} from 'react-planner-fork';
// import { render } from '@testing-library/react';

// import ReactPlannerOverride from "./ReactPlannerOverride";

import store from './store';
import { MContext } from './settingsContext';

// //define state
// let AppState = Map({
//   'react-planner': new PlannerModels.State()
// });

// //define reducer
// let reducer = (state, action) => {
//   state = state || AppState;
//   state = state.update('react-planner', plannerState => PlannerReducer(plannerState, action));
//   return state;
// };

// let store = createStore(reducer, null, window.devToolsExtension ? window.devToolsExtension() : f => f);

let plugins = [
  // PlannerPlugins.Keyboard(),
  // PlannerPlugins.Autosave('react-planner_v0'),
  // PlannerPlugins.ConsoleDebugger(),
];


// export default class Layout extends React.Component {
export default function() {

  const context = useContext(MContext);
  const settingsImage = context.state.settingsImage;

  return (
    // <div className="Layout">
    //   <header className="Layout-header">
        <Provider store={store}>
          <ContainerDimensions>
          {({width, height}) =>
            <ReactPlanner
              catalog={MyCatalog}
              width={width}
              height={height}
              plugins={plugins}
              stateExtractor={state => state.get('react-planner')}
              overrides={{
                props: {
                  toolbarButtons: null
                }
              }}
              customContents={{
                imageUri: settingsImage, 
                viewerZoom: 1.33
              }}
            />
          }
          </ContainerDimensions>
        </Provider>
    //   </header>
    // </div>
  );
}
