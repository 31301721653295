import {Map} from 'immutable';
import {
    Models as PlannerModels,
    reducer as PlannerReducer,
} from 'react-planner-fork';


//define state
let AppState = Map({
    'react-planner': new PlannerModels.State()
  });

//define reducer
let reducer = (state, action) => {
    state = state || AppState;
    state = state.update('react-planner', plannerState => PlannerReducer(plannerState, action));
    return state;
};

export default reducer;