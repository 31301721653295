import React, { Component } from "react";
import DraggablePiechart from "./draggable-piechart";

export default class PieChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // openProportion: 33,
            // meetingProportion: 33,
            // closedProportion: 33
        }
    }

    componentDidMount(){
        var { defaultValue } = this.props;

        var proportions = [
            { proportion: defaultValue.settingsOfficeOpen, format: { color: "#b6d6bd", label: 'Open', offsetX: 4, offsetY: 8}},
            { proportion: defaultValue.settingsOfficeMeeting, format: { color: "#b6d6cd", label: 'Meeting', offsetX: -4, offsetY: 2}},
            { proportion: defaultValue.settingsOfficeClosed, format: { color: "#b6cfd6", label: 'Closed', offsetX: 6, offsetY: -4}}
        ];
    
        var setup = {
            canvas: document.getElementById('piechart'),
            // radius: 0.8,
            collapsing: false,
            proportions: proportions,
            drawSegment: drawSegment,
            onchange: this.onPieChartChange,
            drawNode: drawNode
        };
        
        var newPie = new DraggablePiechart(setup);

        function drawSegment(context, piechart, centerX, centerY, radius, startingAngle, arcSize, format, collapsed) {
            if (collapsed) { return; }

            var endingAngle = startingAngle + arcSize;
            var r = startingAngle + (endingAngle - startingAngle) / 3
            var fontSize = Math.floor(context.canvas.height / 25);
            var dx = radius - fontSize;
            var dy = centerY / 10;
            var p = Math.round(100 * arcSize / (Math.PI * 2))

            // // Draw segment
            // context.save();
            // context.beginPath();
            // context.moveTo(centerX, centerY);
            // context.arc(centerX, centerY, radius, startingAngle, endingAngle, false);
            // context.closePath();
            // context.fillStyle = format.color;
            // context.fill();
            // context.stroke();
            // context.restore();

            ////////////

            // Draw segment
            context.save();
            var endingAngle = startingAngle + arcSize;
            context.beginPath();
			
			var newCenterX = centerX + format.offsetX;
			var newCenterY = centerY + format.offsetY;
						
			// base layer
            context.moveTo(newCenterX, newCenterY);
            context.arc(newCenterX, newCenterY, radius, startingAngle, endingAngle, false);
            context.closePath();

            context.fillStyle = '#97C7BF';
            context.fill();
			// context.strokeStyle = '#000';
            // context.stroke();
            context.restore();
			
			
			// orange line layer
			var offsetBX = 2
			var offsetBY = -6
			
			var newCenterBX = centerX + format.offsetX + offsetBX;
			var newCenterBY = centerY + format.offsetY + offsetBY;
			context.beginPath();
			
			context.moveTo(newCenterBX, newCenterBY);
            context.arc(newCenterBX, newCenterBY, radius, startingAngle, endingAngle, false);
            context.closePath();

            // context.fillStyle = '#97C7BFCC';
            // context.fill();
			context.strokeStyle = '#e5a76d';
            context.stroke();
            context.restore();
			
			// black line layer
			
			var offsetCX = -4
			var offsetCY = -4
			
			var newCenterCX = centerX + format.offsetX + offsetCX;
			var newCenterCY = centerY + format.offsetY + offsetCY;
			
			context.beginPath();
			context.moveTo(newCenterCX, newCenterCY);
            context.arc(newCenterCX, newCenterCY, radius, startingAngle, endingAngle, false);
            context.closePath();

            // context.fillStyle = '#97C7BFCC';
            // context.fill();
			context.strokeStyle = '#050505';
            context.stroke();
            context.restore();

            ////////////

            // Draw label on top
            context.save();
            context.fillStyle = '#fff'; // text color on piechart
            context.translate(centerX, centerY);
            context.rotate(r);
            context.textAlign = "right";
            context.font = fontSize + "pt Helvetica";
            context.fillText(format.label + " " + p + "%", dx, dy);

            // Draw percent label
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.globalAlpha = 0.2
            context.font = fontSize * 3 + "pt Helvetica";
            context.fillStyle = "#FF0000";
            context.translate(dx / 1.3, dy);
            context.rotate(-r);
            // context.fillText(p, 0, 0);
            context.restore();
        }

        function drawNode(context, piechart, x, y, centerX, centerY, hover) {
            
            var TAU = Math.PI * 2;

            context.save();
            context.translate(centerX, centerY);
            context.fillStyle = '#fff';
            context.strokeStyle = '#000';
    
            var rad = hover ? 16 : 12;
            context.beginPath();
            context.arc(x, y, rad, 0, TAU, true);
            context.fill();
            // context.stroke();
            context.restore();

            // black line circle 

            context.save();
            context.translate(centerX + 3, centerY - 5);
            context.beginPath();
            context.arc(x, y, rad, 0, TAU, true);
            // context.fill();
            context.stroke();

            context.restore();

        }
    }


	onPieChartChange = (piechart) => {
		var openProportion = piechart.getSliceSizePercentage(0);
		var meetingProportion = piechart.getSliceSizePercentage(1);
		var closedProportion = piechart.getSliceSizePercentage(2);
		this.props.pieChartCallback({
			openProportion: openProportion,
			meetingProportion: meetingProportion,
			closedProportion: closedProportion
		})
	}

    render() {
        const {width, height} = this.props;
        // const { openProportion, meetingProportion, closedProportion } = this.state;
        // console.log(openProportion, meetingProportion, closedProportion);
        return (
            <canvas id="piechart" width={width} height={height}></canvas>
        )
    }        
}