import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    height: "100%",
    // width: "100%",
    flexGrow: 1,
    padding: theme.spacing(4),
    backgroundColor: "#465767"
  },
  title: {
  textAlign: "left",
  color: "#fff"
  },
  task: {
      textAlign: "left",
  },
  paper: {
      width: 360,
      // maxWidth: 500,
      padding: theme.spacing(1),
  },
  paperTransparent: {
    backgroundColor: '#fff0',
  },
  control: {
      // padding: theme.spacing(2),
  },
  image: {
      width: 100,
      height: 100
  },
  body: {
    overflow: 'auto',
    width: '100%',
    // height: 'calc(100vh - 165px)',
    // flexGrow: 1,
    margin: 0,
    padding: '16px 0',
    overflowY: 'scroll',
    whiteSpace: 'nowrap',
  },
  drawerBody: {
    backgroundColor: '#e5a76ddb',
    overflow: 'auto',
    height: 370,
    width: '100%',
    // maxHeight: '200px',
    margin: 0,
    // paddingTop: 8,
    // paddingRight: 20,
    marginTop: 'auto'
  },
  drawerButton: {
    width: '100%',
    fontStyle: 'italic',
    backgroundColor: '#e5a76d',
    '&:hover': {
      backgroundColor: 'coral',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#e5a76d',
      },
    }
  },
  helpDrawer: {
    width: '100%',
    textAlign: 'center',
    
  },
  drawer: {
    flexShrink: 0,
    height: props => props.height,
    
  },
  drawerPaper: { height: props => props.height },
  drawerOpen: {
    height: props => props.height,
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: theme.spacing(2),
  },
  submitButton: {
    borderRadius: 0, 
    border: 'none',
    '&:hover': {
        border: 'none',
    },
    backgroundColor:'#ffffff',
    fontWeight: 'bold',
  },
  submitButtonNeg1: {
    display: 'flex',
    justifyContent: 'center',
    height: '3.5em',
  },
  submitButton0: {
      border: "1px solid grey", 
      marginTop: '1em', 
      width: 'fit-content', 
      height: 'fit-content', 
  },
  submitButton1: {
      backgroundColor: "rgb(151, 199, 191)", 
      position: 'relative', 
      right: -6, 
      bottom: 8,
      '&:hover': {
          right: -2, 
          bottom: 4,
          transition: '0.25s',
          backgroundColor: 'rgb(151 199 191 / 30%)',
      },
  },
  submitButton2: {
      position: 'relative', 
      left: 8, 
      top: 4,
      '&:hover': {
          left: 2, 
          // bottom: 2,
          transition: '0.25s'
      },
  },
}));
