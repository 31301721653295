import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    height: "100%",
    // width: "100%",
    flexGrow: 1,
    padding: theme.spacing(4),
    backgroundColor: "#465767",
  },
  title: {
    textAlign: "left",
    color: "#fff"
  },
  
  body: {
    overflow: 'auto',
    width: '100%',
    // height: 'calc(100vh - 165px)',
    // flexGrow: 1,
    margin: 0,
    padding: '16px 0',
    overflowY: 'scroll',
    whiteSpace: 'nowrap',
  },
  proceedBody: {
    backgroundColor: '#e5a76d',
    overflow: 'auto',
    width: '100%',
    // height: 200,
    // maxHeight: '200px',
    margin: 0,
    paddingBottom: 8,
    // paddingRight: 20,
    marginTop: 'auto',
    textAlign: 'center'
  },
  drawerButton: {
    width: '100%',
    height: '36.5px',
    bottom: "0px",
    position: "fixed",
    fontStyle: 'italic',
    backgroundColor: '#e5a76d',
    '&:hover': {
      backgroundColor: 'coral',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#e5a76d',
      },
    }
  },
  proceedDrawer: {
    width: '100%',
    textAlign: 'center',
  },
  proceedButton: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    
  },
  submitButtonNeg1Mid: {
    display: 'flex',
    justifyContent: 'center',
    height: '4em',
  },
  submitButtonNeg1: {
    position: 'absolute', 
    bottom: 36, 
    right: 18, 
  },
  submitButton0: {
    border: "1px solid grey", 
    marginTop: '1em', 
    width: 'fit-content', 
    height: 'fit-content', 
  },
  submitButton1: {
    backgroundColor: "rgb(151, 199, 191)", 
    position: 'relative', 
    right: -6, 
    bottom: 8,
    '&:hover': {
      right: -2, 
      bottom: 4,
      transition: '0.25s',
      backgroundColor: 'rgb(151 199 191 / 30%)',
    },
  },
  submitButton2: {
    position: 'relative', 
    left: 8, 
    top: 4,
    '&:hover': {
      left: 2, 
      // bottom: 2,
      transition: '0.25s'
    },
  },
  submitButton: {
    borderRadius: 0, 
    border: 'none',
    '&:hover': {
        border: 'none',
    },
    backgroundColor:'#e5a76d',
    fontWeight: 'bold',
    '&:disabled': {
      color: '#666666',
      backgroundColor: '#e0e0e0',
      opacity: 1.0,
    }
  },
  // submitButton: {
  //   borderRadius:50, 
  //   position: 'absolute', 
  //   bottom: 36, 
  //   right: 18, 
  //   backgroundColor:'#97C7BF',
  //   '&:hover': {
  //       backgroundColor: '#7ba8a1',
  //       // Reset on touch devices, it doesn't add specificity
  //       '@media (hover: none)': {
  //         backgroundColor: '#7ba8a1',
  //       },
  //   },
  //   '&:disabled': {
  //     color: '#666666',
  //     backgroundColor: '#e0e0e0',
  //     opacity: 1.0,
  //   }
  // },
}));
